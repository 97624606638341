import i18n from 'i18next'
import en from '../assets/i18n/en.json';
import fr from '../assets/i18n/fr.json';
import { initReactI18next } from 'react-i18next';
import { ELanguage } from '../enums/eLanguage';
import sessionService from '../services/sessionService';

export const changeLanguage = (language: ELanguage) => (
    i18n
    .changeLanguage(language)
    .then(() => sessionService.currentLang.set(language))
);

i18n
    .use(initReactI18next)
    .init({
        lng: sessionService.currentLang.get(),
        fallbackLng: ELanguage.en,
        resources: {
            en: {
                translation: en
                },
            fr: {
                translation: fr
            }
        },
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;