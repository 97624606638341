import {FocusTrapZone, Layer, mergeStyleSets, Popup, Stack} from "@fluentui/react"
import {memo, useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next";
import {withTranslation}  from 'react-i18next';
import LoadingView from "../../components/loadingView"
import {useHttpRequestStatus} from "../../hooks"
import {INews} from "../../interfaces"
import {httpRequestService, sessionService} from "../../services"
import "./MainPage.scss"
import ContingencyPlan from "../contingencyPlan";
import {CreateFormContext} from "../../contexts";

const VARIABLES = {
    initState: {
        news: [],
        newsPage: 0,
        maxPage: 0,
    } as IState
}

interface IState {
    news: INews[]
    newsPage: number
    maxPage: number
}

const MainPage = () => {

    const {isLoading} = useHttpRequestStatus<INews[]>('getNews', []);
    const [state, setState] = useState(VARIABLES.initState);

    const createFormContext = useContext(CreateFormContext);
    const { i18n } = useTranslation('MainPage');
    const getLanguage = () => i18n.language


    const popupStyles = mergeStyleSets({
        root: {
            background: 'rgba(0, 0, 0, 0.3)',
            bottom: '0',
            left: '0',
            position: 'fixed',
            right: '0',
            top: '0',
        },
        content: {
            background: 'white',
            padding: '0 2em 2em',
            position: 'absolute',
            left: "50%",
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },
    });

    const {t} = useTranslation();

    /** Behavior when component is mounted and unmounted */
    useEffect(
        () => {
            /** Execute request */
            httpRequestService.welfarmService.news.getNews({requestId: 'getNews'}).then(response => {
                // Filter reponse on language
                response = response.filter(item => item.language == getLanguage());
                setState(l => ({...l, news: response, maxPage: Math.floor(response.length / 3) + 1}))
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [i18n.language]
    );

    const goLeft = () => {
        if (state.newsPage == 1) {
            setState(l => ({...l, newsPage: state.maxPage}))
        } else {
            setState(l => ({...l, newsPage: state.newsPage - 1}))
        }
    }

    const goRight = () => {
        if (state.newsPage == state.maxPage - 1) {
            setState(l => ({...l, newsPage: 0}))
        } else {
            setState(l => ({...l, newsPage: state.newsPage + 1}))
        }
    }

    return (
        <Stack className="app-mainPage">
            <h1 className="texte">{t("mainPageTitle")}</h1>
            <Stack style={{height: "20%", display: "flex", justifyContent: "center"}} horizontal>
                {!sessionService.user.get() ?
                    <>
                        <Link style={{width: "30%", paddingRight: "5%"}} to="/register">
                            <button className="button">
                                <Stack style={{alignItems: "center", justifyContent: "center"}} horizontal>
                                    {/* <img style={{height:"13%", width:"13%", paddingLeft:"10%", paddingRight:"3%"}}src={require("../../assets/images/editFormIcon.png")}/> */}
                                    <h1 className="buttonTitle">{t("register")}</h1>
                                </Stack>
                            </button>
                        </Link>
                        <Link style={{width: "30%"}} to="/login">
                            <button className="button">
                                <Stack style={{alignItems: "center", justifyContent: "center"}} horizontal>
                                    {/* <img style={{height:"13%", width:"13%", paddingLeft:"10%", paddingRight:"3%"}}src={require("../../assets/images/editContacts.png")}/> */}
                                    <h1 className="buttonTitle">{t("login")}</h1>
                                </Stack>
                            </button>
                        </Link>
                    </>
                    :
                    <>
                        <Link style={{width: "30%", paddingRight: "5%"}} to="/form">
                            <button onClick={() => createFormContext.setValue(l => ({...l, numPage: 1}))}
                                    className="button">
                                <Stack style={{alignItems: "center"}} horizontal>
                                    <img style={{height: "13%", width: "13%", paddingLeft: "10%", paddingRight: "3%"}}
                                         src={require("../../assets/images/editFormIcon.png")}/>
                                    <h1 className="buttonTitle">{t("createFormButton")}</h1>
                                </Stack>
                            </button>
                        </Link>
                        <Link style={{width: "30%"}} to="/contacts">
                            <button className="button">
                                <Stack style={{alignItems: "center"}} horizontal>
                                    <img style={{height: "13%", width: "13%", paddingLeft: "10%", paddingRight: "3%"}}
                                         src={require("../../assets/images/editContacts.png")}/>
                                    <h1 className="buttonTitle">{t("updateContactsButton")}</h1>
                                </Stack>
                            </button>
                        </Link>
                    </>
                }
            </Stack>
            <Stack className="banner">
                <h5 className="newsTitle">{t("news")}</h5>
                <Stack style={{
                    height: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginLeft: "5%",
                    marginRight: "5%"
                }} horizontal>
                    <img onClick={goLeft} style={{height: "20%"}} src={require("../../assets/images/leftArrow.png")}/>
                    {state.news.map((item, index) => (
                        (state.newsPage * 3 <= index && index < state.newsPage * 3 + 3 ?
                            <Stack className="textZone">
                                <span className="textHead">{item.title}</span>
                                <span className="textBody">{item.message}</span>
                            </Stack> : null)
                    ))}
                    <img onClick={goRight} style={{height: "20%"}} src={require("../../assets/images/rightArrow.png")}/>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default memo(MainPage)