import { Dropdown, IDropdownProps } from "@fluentui/react"
import { Controller } from "react-hook-form"
import { IHookFormProps } from "../../interfaces"

type TProps = IHookFormProps & IDropdownProps

const ControlledDropdown = (props: TProps) => (
    <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue || ""}
        render={({
            field: {onChange, value, onBlur},
            fieldState: {error}
        }) => (
            <Dropdown
                {...props}
                onChange={(_e, option) => {
                    onChange(option!.key)
                }}
                selectedKey={value}
                onBlur={onBlur}
                errorMessage={error?.message}
                defaultValue={undefined}
            />
        )}
    />
)

export default ControlledDropdown