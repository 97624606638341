import { memo } from "react"


const AdminPage = () => {

    return (
        <h1>ADMIN</h1>
    )
}

export default memo(AdminPage)