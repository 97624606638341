import { PrimaryButton, Stack } from '@fluentui/react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MessageForm from './AdminMessagesForm';
import { memo, useContext } from 'react';
import { IMessage, IHttpRequestError } from '../../../interfaces';
import { formPanelService, httpRequestService, messageBoxService, sessionService } from '../../../services';
import { EIconName } from '../../../enums';
import { FormPanel, MessageBox, QuickFilter } from '../../../components';
import { AdminMessageContext } from '../../../contexts';
import "./AdminMessages.scss"


const VARIABLES = {
    initFormData: {
        id: -1,
        code: "",
        message: "",
        emergency: true,
        language: sessionService.currentLang.get() ?? "",
    } as IMessage,
};

const AdminMessagesControls = () => {
    /** Translation hook */
    const { t } = useTranslation();
    /** Context of parent component. */
    const adminMessagesContext = useContext(AdminMessageContext);
    
    /** Hook to handle form data */
    const {
        reset: resetForm,
        control: controlForm,
        setError: setErrorForm,
        handleSubmit: handleSubmitForm,
    } = useForm<IMessage>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnChangeQuickFilter = (filterValue: string) => {
        adminMessagesContext.value.gridApi?.setQuickFilter(filterValue);
        adminMessagesContext.setValue(l => ({
            ...l,
            filterValue,
            numberRowsDisplayed: adminMessagesContext.value.gridApi?.getDisplayedRowCount() ?? 0,
        }));
    };

    /** Handler of event 'onClick' on button add adminMessage. */
    const handleOnClickAddAdminMessage = () => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new adminMessage
        formPanelService.publish({
            content: <MessageForm control={controlForm as Control<any>} />,
            title: t("addAdminMessage"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
                handleSubmitForm(
                data =>
                    httpRequestService.welfarmService.messages
                    .createMessage(data, { showErrorMessage: false })
                    .then((id) => {
                        data.id = id
                        // Add in data table
                        adminMessagesContext.setValue(l => ({
                            ...l,
                            rowsDataTable: [...l.rowsDataTable, data],
                        }));
                        // Reselect nodes
                        adminMessagesContext.value.gridApi?.forEachNode(node =>
                        adminMessagesContext.value.rowDataSelected.includes(node.data)
                            ? node.setSelected(true)
                            : node.setSelected(false)
                        );
                    })
                    .catch((error: IHttpRequestError) => {
                        //Update state of invalid field
                        Object.keys(error.Errors ?? {}).forEach(key => {
                        setErrorForm(key.toLowerCase() as keyof IMessage, {
                            message: t("Erreur : " + error.Errors?.[key][0] ?? ''),
                        });
                        });
                        return Promise.reject();
                    }),
                () => Promise.reject(t('invalid'))
            )(),
        });
    };

    /** Handler of event 'onClick' on button remove adminMessages. */
    const handleOnClickDelete = () => {
        messageBoxService.confirm(
        t("sure"),
        () =>
            new Promise((resolve, reject) => {
            // Get data to delete
            const selectedRowIds = adminMessagesContext.value.gridApi?.getSelectedRows().map(l => (l as IMessage).id) ?? [];
                // Execute http request
            httpRequestService.welfarmService.messages
                .removeMessages(selectedRowIds, { showErrorMessage: false })
                .catch((error: any) => reject(error));
            // Remove from data table
            adminMessagesContext.setValue(l => ({
                ...l,
                rowsDataTable: [...l.rowsDataTable.filter(item => !selectedRowIds.includes(item.id))],
                rowDataSelected: [],
            }));
            // Resolve promise
            resolve();
            })
        );
    };

    return (
        <Stack className="adminMessage-controls" horizontal>
            <FormPanel/>
            <MessageBox/>
            <Stack horizontal>
                {adminMessagesContext.value.rowDataSelected.some(_ => true) && (
                <PrimaryButton
                    className="deleteButton"
                    iconProps={{ iconName: EIconName.delete }}
                    onClick={handleOnClickDelete}
                    text={t("remove")}
                />
                )}
                <PrimaryButton
                    className="addButton"
                    iconProps={{ iconName: EIconName.add }}
                    text={t("add")}
                    onClick={handleOnClickAddAdminMessage}
                />
                <QuickFilter value={adminMessagesContext.value.filterValue} onChange={handleOnChangeQuickFilter} />
            </Stack>
        </Stack>
    );
};

export default memo(AdminMessagesControls);
