import { Stack } from "@fluentui/react"
import { memo, useState } from "react"
import { useTranslation } from "react-i18next";
import { IAdminMessageContextState, AdminMessageContext, defaultAdminMessageContext } from "../../../contexts";
import AdminMessagesControls from "./AdminMessagesControls";
import AdminMessagesDatatable from "./AdminMessagesDatatable";
import "./AdminMessages.scss"



const AdminMessagesPage = () => {

    const { t } = useTranslation();
    /** Context of parent component. */
    const [state, setState] = useState<IAdminMessageContextState>(defaultAdminMessageContext.value);
    return(
        <div className="app-adminMessages">
            <AdminMessageContext.Provider value={{value: state, setValue: setState}}>
                <Stack style={{height:"100%",width:"80%", marginLeft:"10%", marginRight:"5%"}}>
                    <Stack style={{display:"flex"}} horizontal>
                        <h1 className="title" style={{alignSelf:"center", paddingLeft:"30%"}}>{t("messages")}</h1>
                    </Stack>
                    <Stack style={{height:"100%", width:"100%"}}>
                        <AdminMessagesControls/>
                        <AdminMessagesDatatable/>
                    </Stack>
                </Stack>
            </AdminMessageContext.Provider>
        </div>
    )
}

export default memo(AdminMessagesPage)