import { GridApi } from "ag-grid-community";
import { createContext } from "react";
import { IContext, IPersonalMessage } from "../interfaces";

export interface IPersonalMessageContextState {
    rowsDataTable: IPersonalMessage[];
    rowDataSelected: IPersonalMessage[];
    filterValue: string;
    gridApi?: GridApi;
}

export const defaultPersonalMessageContext = {
    value: {
        gridApi: undefined,
        rowDataSelected: [],
        filterValue: "",
        rowsDataTable: [],
    },
    setValue: l => l,
} as IContext<IPersonalMessageContextState>;

export const PersonalMessageContext = createContext<IContext<IPersonalMessageContextState>>(defaultPersonalMessageContext);