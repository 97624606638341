import { GridApi } from "ag-grid-community";
import { createContext } from "react";
import { IContact, IContext } from "../interfaces";

export interface IContactContextState {
    rowsDataTable: IContact[];
    rowDataSelected: IContact[];
    filterValue: string;
    gridApi?: GridApi;
}

export const defaultContactContext = {
    value: {
        gridApi: undefined,
        rowDataSelected: [],
        filterValue: "",
        rowsDataTable: [],
    },
    setValue: l => l,
} as IContext<IContactContextState>;

export const ContactsContext = createContext<IContext<IContactContextState>>(defaultContactContext);