import { memo } from "react"
import { Route, Routes} from "react-router-dom"
import AdminPage from "../../pages/adminPage"
import AdminMessage from "../../pages/adminPage/adminMessage"
import ContactsPage from "../../pages/contactsPage"
import CreateFormPage from "../../pages/createFormPage"
import FormPage2 from "../../pages/formPages/formPage2"
import FormPage3 from "../../pages/formPages/formPage3"
import FormPage4 from "../../pages/formPages/formPage4"
import FormPage5 from "../../pages/formPages/formPage5"
import LoginPage from "../../pages/loginPage"
import MainPage from "../../pages/mainPage"
import PersonalMessages from "../../pages/personalMessagesPage/PersonalMessages"
import RegisterPage from "../../pages/registerPage"
import UserPage from "../../pages/userPage"
import "./RoutesPath.scss"

const RoutesPath = () => (
    <div className="app-routesPath">
        <Routes>
            <Route path="/" element={<MainPage />}/>
            <Route path="/contacts" element={<ContactsPage/>}/>
            <Route path="/profile" element={<UserPage/>}/>
            <Route path="/personalMessages" element={<PersonalMessages/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/register" element={<RegisterPage/>}/>
            <Route path="/admin" element={<AdminPage/>}/>
            <Route path="/admin/message" element={<AdminMessage/>}/>
            <Route path="/form" element={<CreateFormPage/>}/>
            <Route path="/formPage2" element={<FormPage2/>}/>
            <Route path="/formPage3" element={<FormPage3/>}/>
            <Route path="/formPage4" element={<FormPage4/>}/>
            <Route path="/formPage5" element={<FormPage5/>}/>
        </Routes>
    </div>
)

export default memo(RoutesPath)