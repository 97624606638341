import { MessageBar, MessageBarType } from '@fluentui/react';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Interface of component's props. */
interface IProps {
    onClose: () => void;
    error?: string | JSX.Element;
}

/** Interface of state */
interface IState {
    isDeleted: boolean;
}

/** Global variables */
const VARIABLES = {
    timeout: 500,
    initState: {
        isDeleted: false,
    } as IState,
};

/**
 * Component used to display alert in component form panel.
 * @props Props of component.
 * @returns JSX.Element.
 */
const FormPanelAlert = ({ error, onClose }: IProps) => {
  /** Translation hook */
    const { t } = useTranslation();
  // State's accessers of component
    const [state, setState] = useState<IState>(VARIABLES.initState);

  // Bahavior when component is mounted and unmounted
    useEffect(() => {
        // Update state
        setState(l => ({ ...l, isDeleted: false }));
    }, [error]);

  /** Handler of event 'onDismiss'. */
    const handleOnDismiss = () => {
        // Update state
        setState(l => ({ ...l, isDeleted: true }));
        // Update state
        setTimeout(() => {
        // Perform
        onClose();
        }, VARIABLES.timeout);
    };

  // Check if error
    if (!error) {
        return <></>;
    }

    return (
        <MessageBar
        className={`my-2 ${state.isDeleted && 'ms-fadeOut500'}`}
        dismissButtonAriaLabel={t('close')}
        messageBarType={MessageBarType.error}
        onDismiss={handleOnDismiss}
        isMultiline={false}
        children={error}
        />
    );
};

export default memo(FormPanelAlert);
