import { HttpRequestContextProvider } from '../hocs';
import Layout from '../layout';
import './App.scss';

const App = () => (
  <div className='app ms-Fabric' dir="ltr">
    <HttpRequestContextProvider>
      <Layout />
    </HttpRequestContextProvider>
  </div>
)

export default App;
