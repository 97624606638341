import { useContext } from 'react';
import { HttpRequestContext } from '../contexts';
import { IHttpRequestStatus } from '../interfaces';

/**
 * Hooks use to get status of http request.
 * @param requestId Id of current http request.
 * @param defaultData Default value of data requested.
 */
const useHttpRequestStatus = <TResponse>(requestId: string, defaultData?: TResponse) => {

    /** Status of application. */
    const httpRequestContext = useContext(HttpRequestContext);

    // Status of request
    const status: IHttpRequestStatus<TResponse> = httpRequestContext.find(l => l.requestId === requestId) ?? { isLoading: true, data: defaultData };
    // Update default value of data requested
    status.data = status.data ?? defaultData as TResponse;

    return status as Omit<IHttpRequestStatus<TResponse>, 'requestId'>;

};

export default useHttpRequestStatus;
