import { Icon } from "@fluentui/react";
import { CellClickedEvent, ColDef, FilterChangedEvent, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { memo, useContext, useEffect } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { Control, useForm } from "react-hook-form";
import { PersonalMessageContext } from "../../contexts";
import { useHttpRequestStatus } from "../../hooks";
import { IHttpRequestError, IPersonalMessage } from "../../interfaces";
import { formPanelService, httpRequestService, sessionService } from "../../services";
import { EIconName } from "../../enums";
import MessageForm from "./MessageForm";
import LoadingView from "../../components/loadingView";
import { AgGrid } from "../../components";

const VARIABLES = {
    updateButton: (t: TFunction<'translation', undefined>) => {
        return (
        <div className='ps-1'>
            <Icon title={t('edit')} role={'button'} iconName={EIconName.edit} />
        </div>
        );
    },
    initFormData: {
        id: -1,
        title: "",
        message: "",
        emergency: true,
        idUser: sessionService.user.get()?.id ?? "",
    } as IPersonalMessage,
};

const PersonalMessagesDatatable = () => {

    const { t } = useTranslation();

    // /** Status of http request */
    const { isLoading } = useHttpRequestStatus<IPersonalMessage[]>('getPersonalMessages', []);

    const personalMessagesContext = useContext(PersonalMessageContext);
    /** Behavior when component is mounted and unmounted */
    useEffect(
        () => {
            /** Execute request */
            httpRequestService.welfarmService.personalMessages.getPersonalMessagesList( sessionService.user.get()?.id ?? -1, {requestId: 'getPersonalMessages'} ).then(response => {
                personalMessagesContext.setValue(l => ({
                ...l,
                rowsDataTable: response,
            }));
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    /** Handler of event 'onGridReady'. */
    const handleOnGridReady = (e: GridReadyEvent) => {
        e.api.sizeColumnsToFit();
        personalMessagesContext.setValue((l: any) => ({
            ...l,
            gridApi: e.api,
        }));
    };

    const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
        const rowDataSelected = event.api.getSelectedRows();
        personalMessagesContext.setValue((l: any) => ({ ...l, rowDataSelected }));
    };

    const handleOnFilterChanged = (event: FilterChangedEvent<IPersonalMessage>) => {
        if (personalMessagesContext.value.gridApi?.getDisplayedRowCount() === 0 ?? true) {
            event.api.showNoRowsOverlay();
        } else {
            event.api.hideOverlay();
        }
    };

    const {
        reset: resetForm,
        control: controlForm,
        handleSubmit: handleSubmitForm,
        setError: setErrorForm,
        setValue: setValueForm,
        } = useForm<IPersonalMessage>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnClickUpdateButton = (event: CellClickedEvent<IPersonalMessage>) => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new contact
        setValueForm('id', event.data?.id ?? -1);
        setValueForm('title', event.data?.title ?? "");
        setValueForm('message', event.data?.message ?? "");
        formPanelService.publish({
            content: <MessageForm control={controlForm as Control<any>} />,
            title: t("updatePersonalMessage"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
            handleSubmitForm(
                data =>
                httpRequestService.welfarmService.personalMessages
                    .updatePersonalMessage(data, { showErrorMessage: false })
                    .then(() => {
                    // Update in data table
                    personalMessagesContext.setValue(l => ({
                        ...l,
                        rowsDataTable: [...l.rowsDataTable.map((item : any) => (item.id === data.id ? data : item))],
                        rowDataSelected: [],
                    }));
                    })
                    .catch((error: IHttpRequestError) => {
                    //Update state of invalid field
                    Object.keys(error.Errors ?? {}).forEach(key => {
                        const field = key.toLowerCase() as keyof IPersonalMessage;
                        setErrorForm(field, {
                        message: 'Erreur : ' + error.Errors?.[key][0] ?? '',
                        });
                    });
                    return Promise.reject();
                    }),
                () => Promise.reject("Formulaire invalide")
            )(),
        });
    };

    const columnDefs = [
        {
            checkboxSelection: true,
            suppressMovable: true,
            headerCheckboxSelection: true,
            flex:0.3,
            wrapText: true,
            autoHeight:true,
        }, 
        {
            headerName: t("messageTitle"),
            field: "title",
            suppressMovable: true,
            flex: 0.4,
            wrapText: true,
            autoHeight:true,
        },
        {
            headerName: t("message"),
            field: "message",
            suppressMovable: true,
            flex: 0.6,
            wrapText: true,
            autoHeight:true,
        },
        {
            suppressMovable: true,
            cellRenderer: () => VARIABLES.updateButton(t),
            onCellClicked: handleOnClickUpdateButton,
            flex: 0.18,
        },
    ] as ColDef[]
    
    return (
        <AgGrid
            onFilterChanged={handleOnFilterChanged}
            rowData={personalMessagesContext.value.rowsDataTable}
            columnDefs={columnDefs}
            onGridReady={handleOnGridReady}
            rowSelection='multiple'
            rowMultiSelectWithClick={true}
            onSelectionChanged={handleOnSelectionChanged}
            pagination={true}
            paginationAutoPageSize={true}
        />
    )
    }

export default memo(PersonalMessagesDatatable)
