import { memo, useContext } from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image,
} from "@react-pdf/renderer";
import { CommandBarButton } from '@fluentui/react';
import { CreateFormContext } from '../../contexts';
import ItemsTable from './ItemsTable';
import "./ChecklistPdf.scss"
import { sessionService } from '../../services';
import { useTranslation } from 'react-i18next';
import { arrayBuffer, buffer } from 'stream/consumers';

// Create Document Component
const ChecklistPdf = () => {
    
    Font.register({
        family: 'Open Sans',
        fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 },
        ]
    });
        
    const createFormContext = useContext(CreateFormContext);
    
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "white",
            color: "black",
            flexDirection: "column",
        },
        section: {
            paddingTop: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 5,
            marginLeft: 20,
            marginRight: 20,
        },
        viewer: {
            width: window.innerWidth*0.9, //the pdf viewer will take up all of the width and height
            height: window.innerHeight*0.9,
        },
        text: {
            fontFamily: "Open Sans",
            fontWeight:600
        }
    });

    const preparation = {
        items: [
            {
                id: 1,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Le moyen de transport est-il en bon état et adapté ?":
                "Is the means of transportation in good condition and suitable?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Inspecter le véhicule pour limiter les risques de panne (gonflage des pneus, huile…). Un générateur de secours doit être présent afin que les ventilateurs puissent fonctionner quelque soit la situation. Assurez-vous que le système de contrôle de température fonctionne, et notamment les capteurs et le système d’alerte et vérifiez les prévisions météorologiques." :
                    "Inspect the vehicle to limit the risk of breakdown (tire inflation, oil, etc.). A backup generator should be present so that the fans can operate in any situation. Make sure the temperature control system is working, including the sensors and warning system, and check the weather forecast.",
            },
            {
                id: 2,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Les conditions de transport ont-elles été adaptées en fonction des conditions climatiques ?":
                "Have the transport conditions been adapted to the climatic conditions?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Vous voyagez en période hivernale. Rouler durant les heures les plus chaudes de la journée. Empêcher la congélation des abreuvoirs et / ou des conduites d’eau par l’utilisation d’appareils de chauffage ou l’ajout de mélanges tels que la glycérine et le glucose dans l’alimentation en eau. En cas de température inférieur à 5°C, veuillez vous référer à la situation d’urgence froid extrême." :
                    "You are traveling during the winter months. Ride during the warmest hours of the day. Prevent freezing of water troughs and/or water lines by using heaters or adding mixtures such as glycerine and glucose to the water supply. In case of temperatures below 5°C, please refer to the emergency situation extreme cold.",
            },
            {
                id: 3,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Avez-vous pris connaissance de l’état des animaux que vous allez transporter ?":
                "Have you taken note of the condition of the animals you will be transporting?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Adapter votre conduite pour qu’elle soit douce et régulière afin d’éviter le stress et la perte d’équilibre de vos animaux." :
                    "Adapt your driving to be smooth and steady to avoid stress and loss of balance of your animals.",
            },
            {
                id: 4,
                titre: sessionService.currentLang.get() == "fr" ? 
                "La quantité d’eau, de nourriture et de litière est-elle suffisante ?":
                "Is there enough water, food and bedding?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Prévoyez des réserves en eau et nourriture pour un voyage d’une durée deux fois supérieur à la durée prévue. Prévoyez une réserve de litière adaptée au nombre d’animaux et aux conditions météorologiques." :
                    "Plan for food and water supplies for a trip that is twice as long as expected. Have a supply of bedding appropriate for the number of animals and the weather conditions.",
            },
            {
                id: 5,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Avez-vous anticipé les moyens de faire face aux urgences ?":
                "Have you planned for emergencies?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Préparer des routes alternatives spécifiques à votre trajet et privilégier la conduite en double équipage. Avoir à sa disposition un dispositif permettant de prendre des photos, un extincteur en état de fonctionnement, du matériel pour réaliser des réparations de fortunes (sangles, cordes…) le matériel nécessaire pour regrouper les animaux en cas d’accident" :
                    "Prepare alternative routes specific to your trip and favor double-crew driving. Have at your disposal a device allowing you to take pictures, a fire extinguisher in working order, material to carry out emergency repairs (straps, ropes...), the necessary material to group the animals in case of an accident",
            },
            {
                id: 6,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Avez-vous consulté le guide de bonnes pratiques relatif au transport de l’espèce concernée ?":
                "Have you consulted the good practice guide for the transport of the species concerned?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Consultez les guides européen de bonnes pratiques relatifs aux transports des animaux pour les espèces concernés : http://www.animaltransportguides.eu/fr/documents/" :
                    "Consult the European guides of good practice relating to the transport of animals for the species concerned: http://www.animaltransportguides.eu/fr/documents/",
            },
            {
                id: 7,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Disposez-vous de tous les documents relatifs au transport des animaux ?":
                "Do you have all the documents related to the transport of the animals?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Vous devez être munis de : - l’autorisation du transporteur de TYPE 2 ;\n - l’agrément du véhicule ;\n - le carnet de route pour les transports internationaux de longue durée ;\n - le certificat de compétence du conducteur ou du convoyeur et le certificat sanitaire des animaux." :
                    "You must have: - TYPE 2 carrier's authorization;\n - vehicle approval;\n - logbook for long-distance international transport;\n - driver's or escort's certificate of competency and animal health certificate.",
            }
        ],
    };

    const chargement = {
        items: [
            {
                id: 1,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Le moyen de transport est-il en bon état et adapté ?":
                "Is the means of transportation in good condition and suitable?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Adapter votre conduite pour qu'elle soit douce et régulière afin d'éviter le stress et la perte d'équilibre de vos animaux" :
                    "Adapt your driving to be smooth and regular to avoid stress and loss of balance of your animals",
            }
        ],
    };

    const during = {
        items: [
            {
                id: 1,
                titre: sessionService.currentLang.get() == "fr" ? 
                "Tous les animaux sont-ils aptes à être transportés ?":
                "Are all animals fit for transport?",
                description: sessionService.currentLang.get() == "fr" ? 
                    "Adapter votre conduite pour qu'elle soit douce et régulière afin d'éviter le stress et la perte d'équilibre de vos animaux" :
                    "Make sure your driving is smooth and regular to avoid stress and loss of balance for the animals.",
            }
        ],
    };

    const { t } = useTranslation();

    return(
        <div className="app-checklistPdf">
            <CommandBarButton
                className="button"
                onClick={() => createFormContext.setValue(l => ({...l, showChecklist: false}))}
            >{t("back")}</CommandBarButton>
            <PDFViewer style={styles.viewer}>
                {/* Start of the document*/}
                <Document>
                    {/*render a single page*/}
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section} wrap={false}>
                            {/* <Image src={`data:image/png;base64,${base64String}`}/> */}
                            <Text style={{fontSize: "20px", fontFamily: "Open Sans", fontWeight: 800}}>{t("checklistDocTitle")}</Text>
                        </View>
                        <View style={styles.section} wrap={false}>
                            <Text style={{fontSize:"15px", textDecoration: "underline", fontFamily: "Open Sans", fontWeight: 800}}>{t("beforeGo")}</Text>
                        </View>
                        <View style={styles.section} wrap={false}>
                            <ItemsTable data={preparation}/>
                        </View>
                        <View style={styles.section} wrap={false}>
                            <Text style={{fontSize:"15px", textDecoration: "underline", fontFamily: "Open Sans", fontWeight: 800}}>{t("loading").toUpperCase()}</Text>
                        </View>
                        <View style={styles.section} wrap={false}>
                            <ItemsTable data={chargement}/>
                        </View>
                        <View style={styles.section} wrap={false}>
                            <Text style={{fontSize:"15px", textDecoration: "underline", fontFamily: "Open Sans", fontWeight: 800}}>{t("during")}</Text>
                        </View>
                        <View style={styles.section} wrap={false}>
                            <ItemsTable data={during}/>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
};

export default memo(ChecklistPdf)