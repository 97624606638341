import { GridApi } from "ag-grid-community";
import { createContext } from "react";
import { IMessage, IContext } from "../../../interfaces";
import AdminMessagePage from "./AdminMessagesPage";

export default AdminMessagePage
export interface IAdminMessageContextState {
    rowsDataTable: IMessage[];
    rowDataSelected: IMessage[];
    filterValue: string;
    gridApi?: GridApi;
    numberRowsDisplayed: number;
}

export const defaultAdminMessageContext = {
    value: {
        gridApi: undefined,
        rowDataSelected: [],
        filterValue: "",
        rowsDataTable: [],
        numberRowsDisplayed: 0,
    },
    setValue: l => l,
} as IContext<IAdminMessageContextState>;

export const AdminMessageContext = createContext<IContext<IAdminMessageContextState>>(defaultAdminMessageContext);