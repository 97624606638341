import { ITextFieldProps, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { IHookFormProps } from "../../interfaces";

type TProps = IHookFormProps & ITextFieldProps

const ControlledTextField = (props: TProps) => (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue || ""}
      render={({ field: { name: fieldName, onChange, onBlur, value }, fieldState: { error } }) => (
        <TextField
          {...props}
          onChange={onChange}
          value={value ?? ""}
          onBlur={onBlur}
          name={fieldName}
          errorMessage={error?.message}
        />
      )}
    />
  );

export default ControlledTextField