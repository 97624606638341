import { CommandBarButton, PrimaryButton, Stack } from '@fluentui/react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ContactsForm from './ContactsForm';
import { memo, useContext } from 'react';
import { IContact, IHttpRequestError } from '../../interfaces';
import { alertService, formPanelService, httpRequestService, messageBoxService, sessionService } from '../../services';
import { EContactType, EIconName } from '../../enums';
import { FormPanel, MessageBox, QuickFilter } from '../../components';
import "./Contacts.scss"
import { ContactsContext } from '../../contexts';


const VARIABLES = {
    initFormData: {
        name: "",
        address: "",
        postalCode: "",
        city: "",
        country: "",
        phone: "",
        mail: "",
        idUser: sessionService.user.get()?.id ?? "",
        contactType: EContactType.all,
    } as IContact,
};

const ContactsControls = () => {
    /** Translation hook */
    const { t } = useTranslation();
    /** Context of parent component. */
    const contactsContext = useContext(ContactsContext);
    
    /** Hook to handle form data */
    const {
        reset: resetForm,
        control: controlForm,
        setError: setErrorForm,
        handleSubmit: handleSubmitForm,
    } = useForm<IContact>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnChangeQuickFilter = (filterValue: string) => {
        contactsContext.value.gridApi?.setQuickFilter(filterValue);
        contactsContext.setValue(l => ({
            ...l,
            filterValue,
        }));
    };

    /** Handler of event 'onClick' on button add contact. */
    const handleOnClickAddContact = () => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new contact
        formPanelService.publish({
            content: <ContactsForm control={controlForm as Control<any>} />,
            title: t("addContact"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
                handleSubmitForm(
                data =>
                    httpRequestService.welfarmService.contacts
                    .createContact(data, { showErrorMessage: false })
                    .then((id) => {
                        // Add in data table
                        data.id = id
                        contactsContext.setValue(l => ({
                            ...l,
                            rowsDataTable: [...l.rowsDataTable, data],
                        }));
                        // Reselect nodes
                        contactsContext.value.gridApi?.forEachNode(node =>
                        contactsContext.value.rowDataSelected.includes(node.data)
                            ? node.setSelected(true)
                            : node.setSelected(false)
                        );
                    })
                    .catch((error: IHttpRequestError) => {
                        //Update state of invalid field
                        Object.keys(error.Errors ?? {}).forEach(key => {
                        setErrorForm(key.toLowerCase() as keyof IContact, {
                            message: t("Erreur : " + error.Errors?.[key][0] ?? ''),
                        });
                        });
                        return Promise.reject();
                    }),
                () => Promise.reject(t('invalid'))
            )(),
        });
    };

    /** Handler of event 'onClick' on button remove contacts. */
    const handleOnClickDelete = () => {
        messageBoxService.confirm(
        t("sure"),
        () =>
            new Promise((resolve, reject) => {
            // Get data to delete
            const selectedRowIds = contactsContext.value.gridApi?.getSelectedRows().map(l => (l as IContact).id) ?? [];
                // Execute http request
            httpRequestService.welfarmService.contacts
                .removeContacts(selectedRowIds, { showErrorMessage: false })
                .catch((error: any) => reject(error));
            // Remove from data table
            contactsContext.setValue(l => ({
                ...l,
                rowsDataTable: [...l.rowsDataTable.filter(item => !selectedRowIds.includes(item.id))],
                rowDataSelected: [],
            }));
            // Resolve promise
            resolve();
            })
        );
    };

    return (
        <Stack className="contact-controls" horizontal>
            <FormPanel/>
            <MessageBox/>
            <Stack horizontal>
                {contactsContext.value.rowDataSelected.some(_ => true) && (
                <PrimaryButton
                    className="deleteButton"
                    iconProps={{ iconName: EIconName.delete }}
                    onClick={handleOnClickDelete}
                    text={t("remove")}
                />
                )}
                <PrimaryButton
                    className="addButton"
                    iconProps={{ iconName: EIconName.add }}
                    text={t("add")}
                    onClick={handleOnClickAddContact}
                />
                <QuickFilter value={contactsContext.value.filterValue} onChange={handleOnChangeQuickFilter} />
            </Stack>
        </Stack>
    );
};

export default memo(ContactsControls);
