import { DefaultButton, Stack } from "@fluentui/react";
import { memo } from "react"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CCurrentUser } from "../../classes";
import { MessageBox } from "../../components";
import ControlledTextField from "../../components/controlledTextField";
import { IHttpRequestError, IRegister } from "../../interfaces";
import { sessionService } from "../../services";
import httpRequestService from "../../services/httpRequestService";
import "./RegisterPage.scss"


const VARIABLES = {
    initFormData: {
        email: "",
        password: "",
        society: "",
        societyAddress: "",
        logo: "",
        phone: ""
    } as IRegister,
};

const RegisterPage = () => {

    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        setError
    } = useForm<IRegister>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const onSubmit = (data: IRegister) => {
        httpRequestService.identityService.users
        .createUser(data, {showErrorMessage: true})
            .then((id) => {
                console.log(id)
                if (id == -1){
                    setError("email", {
                        message: "Cet email est déjà utilisé",
                    });
                }
                else{
                    window.location.href = "/"
                    const currentUser = new CCurrentUser();
                    currentUser.email = data.email;
                    currentUser.id = id;
                    currentUser.password = data.password
                    currentUser.phone = data.phone
                    currentUser.society = data.society
                    currentUser.societyAddress = data.societyAddress
                    currentUser.logo = data.logo
                    sessionService.user.set(currentUser)
                }
            })
            .catch((error: IHttpRequestError) => {
                console.log(error?.Errors ?? "")
                return Promise.reject()
            })
    }

    return(
        <div className="app-register">
            <Stack>
                <MessageBox/>
                <h1 className="title">{t("personalSpace")}</h1>
                <div className="zone">
                <h2 className="subTitle">{t("createAccount")}</h2>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <Stack className="stack" horizontal>
                            <span className="label">{t("mail")}</span>
                            <ControlledTextField
                                control={control}
                                name="email"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        <Stack className="stack" horizontal>
                        <span className="label">{t("password")}</span>
                            <ControlledTextField
                                control={control}
                                type="password"
                                name="password"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        <Stack className="stack" horizontal>
                            <span className="label">{t("society")}</span>
                            <ControlledTextField
                                control={control}
                                name="society"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        <Stack className="stack" horizontal>
                            <span className="label">{t("societyAddress")}</span>
                            <ControlledTextField
                                control={control}
                                name="societyAddress"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        {/* <Stack className="stack" horizontal>
                            <span className="label">{t("logo")} (facultatif)</span>
                            <ControlledTextField
                                control={control}
                                name="logo"
                                maxLength={100}
                            />
                        </Stack> */}
                        <Stack className="stack" horizontal>
                            <span className="label">{t("phone")}</span>
                            <ControlledTextField
                                control={control}
                                name="phone"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        <DefaultButton className="submitButton" type="submit">{t("submit")}</DefaultButton>
                    </form>
                </div>
            </Stack>
        </div>
    )
}

export default memo(RegisterPage)