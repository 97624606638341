export enum EContactType{
    all,
    transport,
    repairman,
    veterinary,
    officialVeterinary,
    control,
    alternativeControl,
    organiser,
    insurance,
    refuel
}