import { memo, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import Header from "./header"
import RoutesPath from "./routesPath/RoutesPath"
import "./Layout.scss"
import Footer from "./footer"
import { CreateFormContext, defaultCreateFormContext, ICreateFormContextState } from "../contexts"

const Layout = () => {

    const [state, setState] = useState<ICreateFormContextState>(defaultCreateFormContext.value);
    
    return(
        <div className="app-layout">
            <BrowserRouter>
                <CreateFormContext.Provider value={{value: state, setValue: setState}}>
                    <Header />
                    <div className="body">
                        <RoutesPath />
                    </div>
                    <Footer/>
                </CreateFormContext.Provider>
            </BrowserRouter>
        </div>
    )
}

export default memo(Layout)