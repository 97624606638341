import React, { memo, useContext } from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image,
} from "@react-pdf/renderer";
import { CommandBarButton } from '@fluentui/react';
import { CreateFormContext } from '../../contexts';
import { IContact, iInformations, IMessage, IPersonalMessage } from '../../interfaces';
import { useTranslation } from 'react-i18next';
import "./ContingencyPlan.scss"

// Create Document Component
const ContingencyPlan = () => {
    
    const { t } = useTranslation();
    
    const createFormContext = useContext(CreateFormContext);

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 },
        ]
    });
    
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "white",
            color: "black",
            paddingTop:20,
            paddingBottom:20,
        },
        section: {
            flexDirection: "row",
            marginTop: 10,
            marginLeft: 10,
            marginRight: 10,
            paddingTop: 10,
            paddingLeft:10,
            paddingRight:10
        },
        viewer: {
            width: window.innerWidth*0.9, //the pdf viewer will take up all of the width and height
            height: window.innerHeight*0.9,
        },
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
        },
        row: {
            flexDirection: "row",
            paddingTop:"0.1%",
            paddingBottom:"0.1%",
            border:1,
            borderColor: "grey",
            padding: 2,
            paddingLeft: 10,
            marginBottom:"0.3%"
        },
        messagesRow: {
            flexDirection: "row",
            paddingTop:"2%",
            borderBottom: 1,
            borderColor: "grey",
            paddingBottom:"2%"
        },
        name: {
            width: "13%",
            paddingLeft:"1%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        address: {
            width: "20%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        postalCode: {
            width: "5%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        city: {
            width: "13%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        country: {
            width: "10%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        phone: {
            width: "14%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        mail: {
            width: "22%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        contactType: {
            width: "15%",
            paddingRight:"5%",
            fontFamily: "Open Sans",
            fontSize: "7px",
        },
        titre: {
            width: "30%",
            paddingRight:"5%",
            fontFamily: "Open Sans", 
            fontWeight: 700,
            fontSize: "7px",
        },
        personalMessage: {
            width: "70%",
            fontSize: "7px",
        },
        message: {
            width: "70%",
            fontSize: "7px",
        },
        formTitle: {
            width: "30%",
            borderRight:1,
            fontSize: "7px",
        },
        formData: {
            width: "70%",
            paddingLeft:10,
            fontSize: "7px",
        },
    });

    const dateFormat = (date: Date) => {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    const data = createFormContext.value.formData;

    const formdataRows =
    Object.keys(data).map((keyName, _) => {
        if (keyName == "arrivalDate"){
            return(
                <View style={styles.row}>
                    <Text style={styles.formTitle}>{t("arrivalDate")}</Text>
                    <Text style={styles.formData}>{dateFormat(data.arrivalDate)}</Text>
                </View>
            )
        }
        if (keyName == "departureDate"){
            return(
                <View style={styles.row}>
                    <Text style={styles.formTitle}>{t("departureDate")}</Text>
                    <Text style={styles.formData}>{dateFormat(data.departureDate)}</Text>
                </View>
            )
        }
        if (keyName == "gotExit"){
            return(
                <View style={styles.row}>
                    <Text style={styles.formTitle}>{t(keyName)}</Text>
                    <Text style={styles.formData}>{data[keyName] ? t("Yes") : t("No")}</Text>
                </View>
            )
        }
        if (keyName != "language"){
            return(
                <View style={styles.row}>
                    <Text style={styles.formTitle}>{t(keyName)}</Text>
                    <Text style={styles.formData}>{data[keyName as keyof iInformations].toString()}</Text>
                </View>
            )
        }
    })

    const contactsRows = createFormContext.value.contacts.map((contact : IContact) => (
        <View style={styles.row} key={contact.id.toString()}>
            <Text style={styles.name}>{contact.name}</Text>
            <Text style={styles.address}>{contact.address}</Text>
            <Text style={styles.postalCode}>{contact.postalCode}</Text>
            <Text style={styles.city}>{contact.city}</Text>
            <Text style={styles.country}>{contact.country}</Text>
            <Text style={styles.phone}>{contact.phone}</Text>
            <Text style={styles.mail}>{contact.mail}</Text>
            <Text style={styles.contactType}>{t(contact.contactType.toString())}</Text>
        </View>
    ));

    const personalMessagesRows = createFormContext.value.personalMessages.map((personalMessage : IPersonalMessage) => (
        <View style={styles.messagesRow} key={personalMessage.id.toString()}>
            <Text style={styles.titre}>{personalMessage.title}</Text>
            <Text style={styles.personalMessage}>{personalMessage.message}</Text>
        </View>
    ));

    const messagesRows = createFormContext.value.messages.map((message : IMessage) => (
        <View style={styles.messagesRow} key={message.id.toString()}>
            <Text style={styles.titre}>{message.title}</Text>
            <Text style={styles.message}>{message.message}</Text>
        </View>
    ));

    let reader = new FileReader()
    return(
        <div className="app-contingencyPlan">
            <CommandBarButton
                className="button"
                onClick={() => createFormContext.setValue(l => ({...l, pdfDone: false}))}
            >{t("back")}</CommandBarButton>
            <PDFViewer style={styles.viewer}>
                {/* Start of the document*/}
                <Document>
                    {/*render a single page*/}
                    <Page size="A4" style={styles.page}>
                        <View style={styles.section}>
                            <Image style={{width:"18px", height:"18px", marginRight: "10px"}} src="https://img.icons8.com/ios/500/siren.png"/>
                            <Text style={{fontSize:"15px", fontFamily: "Open Sans", fontWeight:800}}>{t("contingencyPlan").toUpperCase()}</Text>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.tableContainer}>
                                {formdataRows}
                            </View>
                        </View>
                        <View style={styles.section}>
                            <Image style={{width:"18px", height:"18px", marginRight: "10px"}} src="http://cdn.onlinewebfonts.com/svg/img_27343.png"/>
                            <Text style={{fontSize:"15px", fontFamily: "Open Sans", fontWeight: 800}}>{t("contacts").toUpperCase()}</Text>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.tableContainer}>
                                {contactsRows}
                            </View>
                        </View>
                        <View style={styles.section}>
                            <Image style={{width:"18px", height:"18px", marginRight: "10px", marginTop:"2px"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Iconoir_chat-bubble.svg/480px-Iconoir_chat-bubble.svg.png"/>
                            <Text style={{fontSize:"15px",  fontFamily: "Open Sans", fontWeight: 800}}>{t("contingencyMessages").toUpperCase()}</Text>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.tableContainer}>
                                {personalMessagesRows}
                            </View>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.tableContainer}>
                                {messagesRows}
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
};

export default memo(ContingencyPlan)