import { IHttpRequestServiceOptions, IHttpStatusServiceCommand } from "../../interfaces";
import { Subject } from 'rxjs';
import welfarmService from "./welfarmService";
import identityService from "./identityService";
import { AxiosRequestConfig } from "axios";
import axiosInstance from "../../config/axiosInstance";

const VARIABLES = {
    subject: new Subject<IHttpStatusServiceCommand>(),
    publish: (status: IHttpStatusServiceCommand) => VARIABLES.subject.next(status)
};

const httpRequestService = {

    getStatus: () => VARIABLES.subject.asObservable(),

    request: <TResponse>(
        url: string,
        params: IHttpRequestServiceOptions = {},
        requestConfig: AxiosRequestConfig = {}
    ) => {
        requestConfig = {...requestConfig, url}
        params.showErrorMessage = params.showErrorMessage ?? true;

        VARIABLES.publish({ requestId: params.requestId, type: 'loading'});

        return axiosInstance
            .request<TResponse>(requestConfig)
            .then(response => {
                VARIABLES.publish({ requestId: params.requestId, type: 'success', data: response.data});
                return response
            })
            .catch(error => {
                // Publish command
                VARIABLES.publish({ requestId: params.requestId, type: 'fail', data: error });
                // Display error message
                params.showErrorMessage && console.log(error);
                // Throw error
                throw error;
            })
            .finally(() => {
                // Publish command
                VARIABLES.publish({ requestId: params.requestId, type: 'completed' });
            });
    },
    welfarmService,
    identityService
}

export default httpRequestService;