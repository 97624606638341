import { createContext } from "react";
import { IContact, IContext, iInformations, IMessage, IPersonalMessage } from "../interfaces";
import { sessionService } from "../services";

export interface ICreateFormContextState {
    pdfDone: boolean
    formData: iInformations
    messages: IMessage[],
    personalMessages: IPersonalMessage[],
    contacts: IContact[],
    numPage: number,
    showChecklist: boolean,
}

export const defaultCreateFormContext = {
    value: {
        pdfDone: false,
        showChecklist: false,
        formData: {
            arrivalDate: new Date(),
            departureDate: new Date(),
            arrivalPlace: "",
            departurePlace: "",
            departureVeterinary: "",
            arrivalVeterinary: "",
            gotExit: false,
            borderCrossed: 0,
            organiser: "",
            species: "",
            state: "",
            destiny: "",
            destination: "",
            routes: "",
            transfer: "",
            language: sessionService.currentLang.get()
        },
        messages: [],
        personalMessages: [],
        contacts: [],
        numPage: 1
    },
    setValue: l => l,
} as IContext<ICreateFormContextState>;

export const CreateFormContext = createContext<IContext<ICreateFormContextState>>(defaultCreateFormContext);