import { Control } from 'react-hook-form';
import { IDropdownOption, Stack } from '@fluentui/react';
import { memo } from 'react';
import { ControlledCheckbox, ControlledTextField } from '../../../components';
import { useTranslation } from 'react-i18next';
import ControlledDropdown from '../../../components/controlledDropdown';

/** Interface of component's props */
interface IProps {
    control: Control<any>;
}

const AdminMessagesForm = ({ control }: IProps) => {
    
    const { t } = useTranslation();

    const dropdownOptions: IDropdownOption[] = [
        {key: "fr", text: t("french")},
        {key: "en", text: t("english")},
    ]
    
    return (
        <Stack>
            <ControlledTextField
                control={control}
                className='mb-2'
                name="code"
                rules={{ required: "Requis" }}
                disabled={control._formState.isSubmitting}
                label={t("code")}
                maxLength={100}
            />
            <ControlledTextField
                control={control}
                className='mb-2'
                name="title"
                rules={{ required: "Requis" }}
                disabled={control._formState.isSubmitting}
                label={t("title")}
                maxLength={200}
            />
            <ControlledTextField
                control={control}
                name="message"
                multiline
                rows={5}
                disabled={control._formState.isSubmitting}
                label={t("message")}
                maxLength={500}
            />
            <ControlledCheckbox
                control={control}
                className='mb-2'
                name="emergency"
                disabled={control._formState.isSubmitting}
                label={t("emergency")}
            />
            <ControlledDropdown
                control={control}
                className='mb-2'
                name="language"
                rules={{ required: "Requis" }}
                disabled={control._formState.isSubmitting}
                label={t("language")}
                options={dropdownOptions}
            />
        </Stack>
    );
};

export default memo(AdminMessagesForm);
