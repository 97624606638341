import { Icon } from "@fluentui/react";
import { CellClickedEvent, ColDef, FilterChangedEvent, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { memo, useContext, useEffect } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { Control, useForm } from "react-hook-form";
import { AdminMessageContext } from "../../../contexts";
import { useHttpRequestStatus } from "../../../hooks";
import { IHttpRequestError, IMessage } from "../../../interfaces";
import { formPanelService, httpRequestService, sessionService } from "../../../services";
import { EIconName, ELanguage } from "../../../enums";
import MessageForm from "./AdminMessagesForm";
import LoadingView from "../../../components/loadingView";
import { AgGrid } from "../../../components";

const VARIABLES = {
    updateButton: (t: TFunction<'translation', undefined>) => {
        return (
        <div className='ps-1'>
            <Icon title={t('edit')} role={'button'} iconName={EIconName.edit} />
        </div>
        );
    },
    initFormData: {
        id: -1,
        code: "",
        message: "",
        emergency: true,
        language: sessionService.currentLang.get() ?? "",
    } as IMessage,
};

const AdminMessagesDatatable = () => {

    const { t } = useTranslation();

    // /** Status of http request */
    const { isLoading } = useHttpRequestStatus<IMessage[]>('getAdminMessages', []);

    const adminMessagesContext = useContext(AdminMessageContext);
    /** Behavior when component is mounted and unmounted */
    useEffect(
        () => {
            /** Execute request */
            httpRequestService.welfarmService.messages.getMessagesList(ELanguage.any, {requestId: 'getAdminMessages'} ).then(response => {
                adminMessagesContext.setValue(l => ({
                ...l,
                rowsDataTable: response,
            }));
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    /** Handler of event 'onGridReady'. */
    const handleOnGridReady = (e: GridReadyEvent) => {
        e.api.sizeColumnsToFit();
        adminMessagesContext.setValue((l: any) => ({
            ...l,
            gridApi: e.api,
        }));
    };

    const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
        const rowDataSelected = event.api.getSelectedRows();
        adminMessagesContext.setValue((l: any) => ({ ...l, rowDataSelected }));
    };

    const handleOnFilterChanged = (event: FilterChangedEvent<IMessage>) => {
        if (adminMessagesContext.value.gridApi?.getDisplayedRowCount() === 0 ?? true) {
            event.api.showNoRowsOverlay();
        } else {
            event.api.hideOverlay();
        }
    };

    const {
        reset: resetForm,
        control: controlForm,
        handleSubmit: handleSubmitForm,
        setError: setErrorForm,
        setValue: setValueForm,
        } = useForm<IMessage>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnClickUpdateButton = (event: CellClickedEvent<IMessage>) => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new contact
        setValueForm('id', event.data?.id ?? -1);
        setValueForm('code', event.data?.code ?? "");
        setValueForm('title', event.data?.title ?? "");
        setValueForm('message', event.data?.message ?? "");
        setValueForm('emergency', event.data?.emergency ?? false);
        setValueForm('language', event.data?.language ?? ELanguage.any);
        formPanelService.publish({
            content: <MessageForm control={controlForm as Control<any>} />,
            title: t("updateAdminMessage"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
            handleSubmitForm(
                data =>
                httpRequestService.welfarmService.messages
                    .updateMessage(data, { showErrorMessage: false })
                    .then(() => {
                    // Update in data table
                    adminMessagesContext.setValue(l => ({
                        ...l,
                        rowsDataTable: [...l.rowsDataTable.map((item : any) => (item.id === data.id ? data : item))],
                        rowDataSelected: [],
                    }));
                    })
                    .catch((error: IHttpRequestError) => {
                    //Update state of invalid field
                    Object.keys(error.Errors ?? {}).forEach(key => {
                        const field = key.toLowerCase() as keyof IMessage;
                        setErrorForm(field, {
                        message: 'Erreur : ' + error.Errors?.[key][0] ?? '',
                        });
                    });
                    return Promise.reject();
                    }),
                () => Promise.reject(t("invalid"))
            )(),
        });
    };

    const columnDefs = [
        {
            checkboxSelection: true,
            suppressMovable: true,
            headerCheckboxSelection: true,
            flex:0.05,
        }, 
        {
            headerName: t("code"),
            field: "code",
            suppressMovable: true,
            flex: 0.1,
            wrapText: true,
            autoHeight:true,
        },
        {
            headerName: t("title"),
            field: "title",
            suppressMovable: true,
            flex: 0.5,
            wrapText: true,
            autoHeight:true,

        },
        {
            headerName: t("message"),
            field: "message",
            suppressMovable: true,
            flex: 1,
            wrapText: true,
            autoHeight:true,

        },
        {
            headerName: t("emergency"),
            field: "emergency",
            suppressMovable: true,
            flex: 0.1,
            wrapText: true,
            autoHeight:true,
        },
        {
            headerName: t("language"),
            field: "language",
            suppressMovable: true,
            flex: 0.2,
            wrapText: true,
            autoHeight:true,
        },
        {
            suppressMovable: true,
            cellRenderer: () => VARIABLES.updateButton(t),
            onCellClicked: handleOnClickUpdateButton,
            flex: 0.05,
        },
    ] as ColDef[]
    
    return (
        <AgGrid
            onFilterChanged={handleOnFilterChanged}
            rowData={adminMessagesContext.value.rowsDataTable}
            columnDefs={columnDefs}
            onGridReady={handleOnGridReady}
            rowSelection='multiple'
            rowMultiSelectWithClick={true}
            onSelectionChanged={handleOnSelectionChanged}
            pagination={true}
            paginationAutoPageSize={true}
        />
    )
    }

export default memo(AdminMessagesDatatable)
