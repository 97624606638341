import { DefaultButton, Icon, Link, Stack } from "@fluentui/react"
import { memo, useState } from "react"
import { useTranslation } from "react-i18next";
import { EIconName } from "../../enums";
import { IPersonalMessageContextState, PersonalMessageContext, defaultPersonalMessageContext } from "../../contexts";
import PersonalMessagesControls from "./PersonalMessagesControls";
import PersonalMessagesDatatable from "./PersonalMessagesDatatable";
import "./PersonalMessages.scss"



const PersonalMessagePage = () => {

    const { t } = useTranslation();
    /** Context of parent component. */
    const [state, setState] = useState<IPersonalMessageContextState>(defaultPersonalMessageContext.value);
    return(
        <div className="app-personalMessages">
            <PersonalMessageContext.Provider value={{value: state, setValue: setState}}>
                <Stack style={{height:"100%",width:"80%", marginLeft:"10%", marginRight:"5%"}}>
                    <Stack style={{display:"flex"}} horizontal>
                        <h1 className="title" style={{alignSelf:"center", paddingLeft:"30%"}}>{t("personalMessages")}</h1>
                    </Stack>
                    <Stack style={{height:"100%", width:"100%"}}>
                        <PersonalMessagesControls/>
                        <PersonalMessagesDatatable/>
                    </Stack>
                </Stack>
            </PersonalMessageContext.Provider>
        </div>
    )
}

export default memo(PersonalMessagePage)