import { Control } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import { memo } from 'react';
import { ControlledTextField } from '../../components';
import { useTranslation } from 'react-i18next';

/** Interface of component's props */
interface IProps {
    control: Control<any>;
}

const MessageForm = ({ control }: IProps) => {
    
    const { t } = useTranslation();

    return (
        <Stack>
            <ControlledTextField
                control={control}
                className='mb-2'
                name="title"
                rules={{ required: "Requis" }}
                disabled={control._formState.isSubmitting}
                label={t("messageTitle")}
                rows={3}
                multiline
                maxLength={200}
            />
            <ControlledTextField
                control={control}
                name="message"
                multiline
                rows={5}
                disabled={control._formState.isSubmitting}
                label={t("message")}
                maxLength={500}
            />
        </Stack>
    );
};

export default memo(MessageForm);
