import { IHttpRequestStatus, IHttpStatusServiceCommand } from '../interfaces';
import { HttpRequestContext } from '../contexts/httpRequestContext';
import { memo, useEffect, useState } from 'react';
import { httpRequestService } from '../services';
import { filter } from 'rxjs';

/** Interface of component's props. */
interface IProps {
    children: JSX.Element;
}

/** Interface of state */
interface IState {
    statuses: IHttpRequestStatus<any>[];
}

/**
 * Component used to wrap context 'HttpRequestContext'.
 * @param props Component's props.
 * @returns
 */
const HttpRequestContextProvider = ({ children }: IProps) => {
  // State's accessers of component.
    const [state, setState] = useState<IState>({ statuses: [] });

    const getStatuses = (command: IHttpStatusServiceCommand) =>
        [...state.statuses].map(item => {
        // Check if current item
        if (item.requestId === command.requestId) {
            // Update property 'data'
            item.data = command.type === 'success' ? command.data : item.data;
            // Update property 'error'
            if (command.type === 'loading') {
            item.error = undefined;
            } else {
            item.error = command.type === 'fail' ? command.data : item.error;
            }
            // Update property 'isLoading'
            item.isLoading = command.type === 'loading' || command.type === 'success' || command.type === 'fail';
        }

        return item;
        });
    // Triggered when value's changing.
    useEffect(
        () => {
        // Subscription to alert service
        const subscription = httpRequestService
            .getStatus()
            .pipe(filter(l => !!l.requestId))
            .subscribe(command => {
            // If item exit
            if (state.statuses.some(l => l.requestId === command.requestId)) {
                // Copy of statuses
                const _statuses = getStatuses(command);
                // Update state
                setState(l => ({ ...l, statuses: _statuses }));
            } else {
                // Update state
                setState(l => ({
                ...l,
                statuses: [...l.statuses, { requestId: command.requestId, isLoading: true, data: undefined }],
                }));
            }
            });
        // When component is unmounted
        return () => subscription.unsubscribe();
        },
        // eslint-disable-next-line
        [state.statuses.length]
    );

    return <HttpRequestContext.Provider value={state.statuses}>{children}</HttpRequestContext.Provider>;
};

export default memo(HttpRequestContextProvider);
