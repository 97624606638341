/** Enum of distinct icons used by 'Icon' component.*/
export enum EIconName {
    statusCircleCheckmark = 'StatusCircleCheckmark',
    multiSelectMirrored = 'MultiSelectMirrored',
    statusCircleErrorX = 'StatusCircleErrorX',
    statusCircleOuter = 'StatusCircleOuter',
    statusCircleInner = 'StatusCircleInner',
    squareShapeSolid = 'SquareShapeSolid',
    skypeCircleCheck = 'SkypeCircleCheck',
    globalNavButton = 'GlobalNavButton',
    completedSolid = 'CompletedSolid',
    msnVideosSolid = 'MSNVideosSolid',
    localeLanguage = 'LocaleLanguage',
    chevronRight = 'ChevronRight',
    installation = "Installation",
    collapseMenu = 'CollapseMenu',
    fullHistory = 'FullHistory',
    chevronLeft = 'ChevronLeft',
    chevronDown = 'ChevronDown',
    errorBadge = 'ErrorBadge',
    skypeArrow = "SkypeArrow",
    addFriend = 'AddFriend',
    chevronUp = 'ChevronUp',
    completed = 'Completed',
    infoSolid = 'InfoSolid',
    pageEdit = 'PageEdit',
    download = 'Download',
    settings = 'Settings',
    refresh = 'Refresh',
    unknown = 'Unknown',
    warning = 'Warning',
    filter = 'filter',
    ringer = 'Ringer',
    cancel = 'Cancel',
    delete = 'Delete',
    export = 'Export',
    signin = 'Signin',
    admin = 'Admin',
    clear = 'Clear',
    error = 'Error',
    merge = 'Merge',
    split = 'Split',
    mail = 'Mail',
    info = 'Info',
    undo = 'Undo',
    save = 'Save',
    stop = 'Stop',
    edit = 'Edit',
    bug = 'Bug',
    add = 'Add',
}
