import { DefaultButton, Panel, PanelType, Spinner, SpinnerSize, Stack } from '@fluentui/react';

import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFormPanelOptions } from '../../interfaces';
import { formPanelService } from '../../services';
import FormPanelAlert from './FormPanelAlert';


/** Global variables */
const VARIABLES = {
    initState: {
        options: {
        confirmActionCallBack: () => Promise.resolve(),
        confirmActionText: "",
        cancelActionText: "",
        content: <></>,
        title: "",
        },
        isLoading: false,
        isOpen: false,
    } as IState,
};

/** Interface of state */
interface IState{
    options: IFormPanelOptions;
    isOpen: boolean;
    isLoading: boolean;
    error?: string | JSX.Element;
}

/**
 * Component used to display form panel.
 * @returns JSX.Element.
 */
const FormPanel = () => {
  /** Translation hook */
    const { t } = useTranslation();
  // State's accessers of component.
    const [state, setState] = useState<IState>(VARIABLES.initState);

  // Behavior when component is mounted and unmounted
    useEffect(
        () => {
        // Subscription to alert service
            const subscription = formPanelService.getOptions().subscribe(options => {
            // Update state
            setState(l => ({
                ...l,
                isLoading: false,
                isOpen: true,
                options: {
                ...l.options,
                confirmActionCallBack: options.confirmActionCallBack ?? (() => Promise.resolve()),
                cancelActionText: options.cancelActionText ?? t('cancel'),
                confirmActionText: options.confirmActionText ?? t('save'),
                type: options.type ?? PanelType.medium,
                content: options.content,
                title: options.title,
                },
            }));
            });
        // When component is unmounted
        return () => subscription.unsubscribe();
        },
        // eslint-disable-next-line
        []
    );

    const handleOnDismiss = (_?: any) => {
        // Update state
        setState(VARIABLES.initState);
    };

    /**
     * Handler of event 'onClick' on button 'Save change'.
     * @param _
     */
    const handleOnClickSave = (_: any) => {
        // Update state
        setState(l => ({ ...l, isLoading: true }));
        // Perform callback function
        state.options
        ?.confirmActionCallBack?.()
        .then(handleOnDismiss)
        .catch(error => setState(l => ({ ...l, error })))
        .finally(() => setState(l => ({ ...l, isLoading: false })));
    };

    /**
     * Component used to display footer of panel.
     * @returns JSX.Element.
     */
    const handleOnRenderFooterContent = () => (
        <Stack horizontal horizontalAlign='end'>
        <DefaultButton primary style={{marginRight:"2%"}} disabled={state.isLoading} onClick={handleOnClickSave}>
            {state.options.confirmActionText}
            {state.isLoading && <Spinner style={{marginLeft:"1%"}} size={SpinnerSize.small} />}
        </DefaultButton>
        <DefaultButton onClick={handleOnDismiss} disabled={state.isLoading}>
            {state.options.cancelActionText}
        </DefaultButton>
        </Stack>
    );

    /** Handler of event 'onClose' of component 'FormPanelAlert'. */
    const handleOnCloseAlert = () => {
        // Update state
        setState(l => ({ ...l, error: undefined }));
    };

    return (
        <Panel
            onRenderFooterContent={handleOnRenderFooterContent}
            closeButtonAriaLabel={t('close')}
            hasCloseButton={!state.isLoading}
            isLightDismiss={!state.isLoading}
            headerText={state.options.title}
            onDismiss={handleOnDismiss}
            type={state.options.type}
            isFooterAtBottom={true}
            isOpen={state.isOpen}
            >
            <FormPanelAlert error={state.error} onClose={handleOnCloseAlert} />
            {state.options.content}
        </Panel>
    );
};

export default memo(FormPanel);
