import { DefaultButton, IDropdownOption, IDropdownStyles, Stack } from "@fluentui/react"
import { memo, useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ControlledCheckbox, ControlledSpinbutton, ControlledTextField} from "../../../components"
import { IContact, IHttpRequestError } from "../../../interfaces"
import { iInformations } from "../../../interfaces/iInformations"
import { sessionService } from "../../../services"
import httpRequestService from "../../../services/httpRequestService"
import { CreateFormContext } from "../../../contexts"
import DatePicker from "react-datepicker";
import ControlledDropdown from "../../../components/controlledDropdown"
import './FormPage1.scss'
import { useHttpRequestStatus } from "../../../hooks"
import LoadingView from "../../../components/loadingView"

const FormPage1 = () => {

    const { t } = useTranslation();

    const [veterinaryOptions, setVeterinaryOptions] = useState<IDropdownOption[]>([]);

    const createFormContext = useContext(CreateFormContext);

    const { isLoading } = useHttpRequestStatus<IContact[]>('getVeterinary', []);
    /** Behavior when component is mounted and unmounted */
    useEffect(
        () => {
            /** Execute request */
            httpRequestService.welfarmService.contacts.getContactsList( sessionService.user.get()?.id ?? -1, {requestId: 'getVeterinary'} ).then(response => {
                setVeterinaryOptions(response
                    .filter(contact => contact.contactType.toString() == "officialVeterinary")
                    .map((contact, index) => {
                        return {key:index, text:contact.name}}))
        })}, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const speciesOptions: IDropdownOption[] = [
        {key: "PORCINS", text: t("pigs")},
        {key: "BOVINS", text: t("cattle")},
        {key: "CAPRINS", text: t("goats")},
        {key: "OVINS", text: t("sheep")},
        {key: "EQUIDES", text: t("equines")},
        {key: "VOLAILLES", text: t("poultry")},
    ]
    
    const physiologicalStateOptions: IDropdownOption[] = [
        {key: "PAS DE STADE", text: " "},
        {key: "LACTATION", text: t("lactation")},
        {key: "REFORME", text: t("reform")},
        {key: "GESTANTE", text: t("pregnant")},
        {key: "NON SEVRE", text: t("notWean")},
        {key: "MALE ADULTE NON CASTRE", text: t("uncastrated")},
        {key: "POUSSIN / CANETON", text: t("chick")},
    ]

    const destinyOptions: IDropdownOption[] = [
        {key: "ELEVAGE / ENGRAISSEMENT", text: t("breeding")},
        {key: "REPRODUCTION", text: t("reproduction")},
        {key: "ABATTAGE", text: t("slaughter")},
    ]
    
    const destinationOptions: IDropdownOption[] = [
        {key: "INTRA-COMMUNAUTAIRE", text: t("intra")},
        {key: "EXTRA-COMMUNAUTAIRE", text: t("extra")}
    ]
    
    const routesOptions: IDropdownOption[] = [
        {key: "AUTOROUTES", text: t("highways")},
        {key: "ROUTES NATIONALES/DEPARTEMENTALES", text: t("nationalRoads")},
        {key: "ROUTES EUROPEENNES", text: t("europeRoads")},
    ]
    
    const transferOptions: IDropdownOption[] = [
        {key: "TERRE/TERRE", text: t("groundGround")},
        {key: "TERRE/MER", text: t("groundSea")},
        {key: "TERRE/AIR", text: t("groundAir")},
    ]

    const [departureDate, setdepartureDate] = useState(new Date());
    const [arrivalDate, setarrivalDate] = useState(new Date());

    const {
        control,
        handleSubmit,
        reset,
    } = useForm<iInformations>({
        defaultValues: createFormContext.value.formData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const resetForm = () => {
        createFormContext.setValue(l => ({...l, formData: {
            arrivalDate: new Date(),
            departureDate: new Date(),
            arrivalPlace: "",
            departurePlace: "",
            departureVeterinary: "",
            arrivalVeterinary: "",
            gotExit: false,
            borderCrossed: 0,
            organiser: "",
            species: "",
            state: "",
            destiny: "",
            destination: "",
            routes: "",
            transfer: "",
            language: sessionService.currentLang.get()
        }}))
        reset()
    }

    const onSubmit = (data: iInformations) => {
        data.departureDate = departureDate
        data.arrivalDate = arrivalDate
        httpRequestService.welfarmService.forms
            .createForm(data, {showErrorMessage: false})
            .then((d) => {
                data.state = physiologicalStateOptions.filter(op => op.key == data.state)[0].text
                data.destination = destinationOptions.filter(op => op.key == data.destination)[0].text
                data.destiny = destinyOptions.filter(op => op.key == data.destiny)[0].text
                data.routes = routesOptions.filter(op => op.key == data.routes)[0].text
                data.species = speciesOptions.filter(op => op.key == data.species)[0].text
                data.transfer = transferOptions.filter(op => op.key == data.transfer)[0].text
                data.arrivalVeterinary = veterinaryOptions.filter(op => op.key == data.arrivalVeterinary)[0].text
                data.departureVeterinary = veterinaryOptions.filter(op => op.key == data.departureVeterinary)[0].text
                createFormContext.setValue(l => ({...l, formData: data, messages: d, numPage:2}))
            })
            .catch((error: IHttpRequestError) => {
                console.log(error?.Errors ?? "")
                return Promise.reject()
            })
    }

    const onError = (err : any) => {
        console.log(err)
    }
    
    return (
        <Stack className="app-formPage1">
            <form style={{width: "100%", height:"80%"}} onSubmit={handleSubmit(onSubmit, onError)}>
                <Stack style={{width: "100%", height:"100%"}}>
                    <h3 className="titre">{t("selectCriteria")}</h3>
                    <Stack className="formStack" horizontal>
                        <Stack style={{width: "40%", height:"100%", paddingRight:"5%"}}>
                            <Stack horizontal>
                                <span className="label">{t("departureDate")}</span>
                                <DatePicker
                                    className="datepicker"
                                    selected={departureDate}
                                    onChange={(date : any) => setdepartureDate(date)}
                                    selectsStart
                                    startDate={departureDate}
                                    endDate={arrivalDate}
                                />
                            </Stack>
                            <Stack horizontal>
                                <span className="label">{t("departurePlace")}</span>
                                <ControlledTextField
                                    className="textfield"
                                    rules={{ required: t("required") }}
                                    name="departurePlace"
                                    control={control}/>
                            </Stack>
                            <Stack horizontal>
                                <span className="label">{t("organiser")}</span>
                                <ControlledTextField
                                    className="textfield"
                                    name="organiser"
                                    rules={{ required: t("required") }}
                                    control={control}/>
                            </Stack>
                            <Stack horizontal>
                                <span className="label">{t("borderCrossed")}</span>
                                <ControlledSpinbutton
                                    className="spinbutton"
                                    name="borderCrossed"
                                    rules={{ required: t("required") }}
                                    control={control}/>
                            </Stack>
                            <ControlledDropdown
                                className="dropdown"
                                placeholder={t("departureVeterinary")}
                                name="departureVeterinary"
                                rules={{ required: t("required") }}
                                options={veterinaryOptions}
                                control={control}/>
                            <ControlledDropdown
                                className="dropdown"
                                placeholder={t('species')}
                                name="species"
                                rules={{ required: t("required") }}
                                options={speciesOptions}
                                control={control}/>
                            <ControlledDropdown 
                                className="dropdown"
                                placeholder={t('state')}
                                name="state"
                                rules={{ required: t("required") }}
                                options={physiologicalStateOptions}
                                control={control}/>
                            <ControlledDropdown 
                                className="dropdown"
                                placeholder={t('destination')}
                                name="destination"
                                rules={{ required: t("required") }}
                                options={destinationOptions}
                                control={control}/>
                        </Stack>
                        <Stack style={{width: "40%", height:"100%", paddingLeft:"5%"}}>
                            <Stack horizontal>
                                <span className="label">{t("arrivalDate")}</span>
                                <DatePicker
                                    className="datepicker"
                                    selected={arrivalDate}
                                    onChange={(date : any) => setarrivalDate(date)}
                                    selectsEnd
                                    startDate={departureDate}
                                    endDate={arrivalDate}
                                    minDate={departureDate}
                                />
                            </Stack>
                            <Stack horizontal>
                                <span className="label">{t("arrivalPlace")}</span>
                                <ControlledTextField
                                    className="textfield"
                                    name="arrivalPlace"
                                    rules={{ required: t("required") }}
                                    control={control}/>
                            </Stack>
                            <Stack horizontal>
                                <span className="label">{t("gotExit")}</span>
                                <ControlledCheckbox
                                    className="checkbox"
                                    name="gotExit"
                                    control={control}/>
                            </Stack>
                            <ControlledDropdown 
                                className="dropdown"
                                placeholder={t('routes')}
                                name="routes"
                                rules={{ required: t("required") }}
                                options={routesOptions}
                                control={control}/>
                            <ControlledDropdown 
                                className="dropdown"
                                placeholder={t("arrivalVeterinary")}
                                name="arrivalVeterinary"
                                rules={{ required: t("required") }}
                                options={veterinaryOptions}
                                control={control}/>
                            <ControlledDropdown 
                                className="dropdown"
                                placeholder={t('destiny')}
                                name="destiny"
                                rules={{ required: t("required") }}
                                options={destinyOptions}
                                control={control}/>
                            <ControlledDropdown 
                                className="dropdown"
                                placeholder={t('transfer')}
                                name="transfer"
                                rules={{ required: t("required") }}
                                options={transferOptions}
                                control={control}/>
                        </Stack>
                    </Stack>
                    <Stack horizontal>
                        <DefaultButton type="submit" className="submitButton">{t("bigSubmit")}</DefaultButton>
                        <DefaultButton onClick={() => resetForm()} className="resetButton">{t("resetForm")}</DefaultButton>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    )
}

export default memo(FormPage1)