import { Control } from 'react-hook-form';
import { IDropdownOption, Stack } from '@fluentui/react';
import { memo } from 'react';
import { ControlledTextField } from '../../components';
import { useTranslation } from 'react-i18next';
import ControlledDropdown from '../../components/controlledDropdown';

/** Interface of component's props */
interface IProps {
  control: Control<any>;
}
const ContactsForm = ({ control }: IProps) => {

  const { t } = useTranslation();

  const typeOptions: IDropdownOption[] = [
    {key: "transport", text: t("transport")},
    {key: "repairman", text: t("repairman")},
    {key: "veterinary", text: t("veterinary")},
    {key: "officialVeterinary", text: t("officialVeterinary")},
    {key: "control", text: t("control")},
    {key: "alternativeControl", text: t("alternativeControl")},
    {key: "organiser", text: t("organiser")},
    {key: "insurance", text: t("insurance")},
    {key: "refuel", text: t("refuel")},
  ]

  return (
    <Stack>
      <ControlledDropdown
        control={control}
        options={typeOptions}
        name="contactType"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("activity")}
      />
      <ControlledTextField
        control={control}
        className='mb-2'
        name="name"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("name")}
        maxLength={100}
      />
      <ControlledTextField
        control={control}
        name="address"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("address")}
        maxLength={100}
      />
      <ControlledTextField
        control={control}
        name="city"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("city")}
        maxLength={100}
      />
      <ControlledTextField
        control={control}
        name="country"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("country")}
        maxLength={100}
      />
      <ControlledTextField
        control={control}
        name="phone"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("phone")}
        maxLength={100}
      />
      <ControlledTextField
        control={control}
        name="mail"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("mail")}
        maxLength={100}
      />
      <ControlledTextField
        control={control}
        name="postalCode"
        rules={{ required: t("required") }}
        disabled={control._formState.isSubmitting}
        label={t("postalCode")}
        maxLength={100}
      />
    </Stack>
  );
};

export default memo(ContactsForm);
