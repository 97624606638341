import { CCurrentUser } from "../classes";
import { ELanguage } from "../enums/eLanguage";
import { ESessionKeys } from "../enums/eSessionKeys";
import { ISessionObject } from "../interfaces/iSessionObject";

const currentLang: ISessionObject<ELanguage> = {
    /** Get value. */
    get: (): ELanguage => {
        // Get value.
        const stringValue = localStorage.getItem(ESessionKeys.lang);

        // Set default value if not found
        if (!stringValue) {
        // Set in session.
            currentLang.set(ELanguage.en);
        // Return value
            return currentLang.get();
        }

        return stringValue as ELanguage;
    },
    /**
     * Set value in session.
     * @param value Value applied.
     */
    set: (value: ELanguage) => {
        // Save in session
        localStorage.setItem(ESessionKeys.lang, value);
    },
};

const user: ISessionObject<CCurrentUser | null> = {
    /** Get value. */
    get: (): CCurrentUser | null => {
      // Get value.
        const stringValue = localStorage.getItem(ESessionKeys.user);

      // Set default value if not found
        if (!stringValue) {
            return null;
        }

        return Object.assign(new CCurrentUser(), JSON.parse(stringValue));
    },
    /**
     * Set value in session.
     * @param value Value applied.
     */
    set: (value: CCurrentUser | null) => {
      // If value is not set
        if (!value) {
        // Remove ssession
            localStorage.removeItem(ESessionKeys.user);
        // Quit function
        return;
    }

      // Get value.
    const stringValue = JSON.stringify(value);
      // Save in session
    localStorage.setItem(ESessionKeys.user, stringValue);
    },
};


const sessionService = {
    currentLang,
    user
};

export default sessionService