import { memo } from "react"
import { useTranslation } from "react-i18next";
import "./Footer.scss"

const Footer = () => {

    const { t } = useTranslation();
    
    return (
        <div className="app-footer">
            <span className="texte">{t("footerMessage")}</span>
            <br/>
            <span className="texte">{t("footerLinks")}</span>
        </div>
    )
}

export default memo(Footer)