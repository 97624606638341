import { memo, useContext } from "react"
import { DefaultButton, Stack } from "@fluentui/react"
import "./FormPage4.scss"
import { CreateFormContext } from "../../../contexts"
import { AgGrid } from "../../../components"
import { useTranslation } from "react-i18next"
import { ColDef, GridReadyEvent, ICellRendererParams } from "ag-grid-community"

const FormPage4 = () => {

    const { t } = useTranslation();
    
    const createFormContext = useContext(CreateFormContext);

    const dateFormat = (date: Date) => {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }
    
    const onSubmit = () => {
        createFormContext.setValue(l => ({...l, numPage:5}))
    }

    const backForm = () => {
        createFormContext.setValue(l => ({...l, numPage:1}))
    }

    const backContacts = () => {
        createFormContext.setValue(l => ({...l, numPage:2}))
    }

    const backMessages = () => {
        createFormContext.setValue(l => ({...l, numPage:3}))
    }

    const handleOnGridReady = (e: GridReadyEvent) => {
        e.api.sizeColumnsToFit()
    }

    const columnDefsContacts = [
        {
            headerName: t("name"),
            field: "name",
            suppressMovable: true,
            flex:0.7,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("address"),
            field: "address",
            suppressMovable: true,
            flex:1.3,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("postalCode"),
            field: "postalCode",
            suppressMovable: true,
            flex:0.7,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("city"),
            field: "city",
            suppressMovable: true,
            flex:0.8,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("country"),
            field: "country",
            suppressMovable: true,
            flex:0.6,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("phone"),
            field: "phone",
            suppressMovable: true,
            flex:0.8,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("mail"),
            field: "mail",
            suppressMovable: true,
            flex:1.2,
            wrapText: true,
            autoHeight:true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("activity"),
            field: "contactType",
            suppressMovable: true,
            cellStyle: {fontSize: "12px"},
            cellRenderer: (props: ICellRendererParams<any, any>) => {
                return t(props.data.contactType)
            },
        },
    ] as ColDef[]

    const columnDefsMessages = [
        {
            headerName: t("messageTitle"),
            field: "title",
            suppressMovable: true,
            flex: 1,
            wrapText: true,
            autoHeight:true,
        },
        {
            headerName: t("message"),
            field: "message",
            suppressMovable: true,
            flex: 1,
            wrapText: true,
            autoHeight:true,
        },
    ] as ColDef[]

    return(
        <Stack className="app-formPage4">
            <Stack style={{height:"100%", width:"80%"}}>
                <div className="box">
                    <Stack horizontal>
                        <span className="titre">{t("dataRecap")}</span>
                        <DefaultButton onClick={backForm} className="resetButton">{t("edit")}</DefaultButton>
                    </Stack>
                    <Stack className="infos">
                        <span>{`${t("departureDate")} : ${dateFormat(createFormContext.value.formData.departureDate)}`}</span>
                        <span>{`${t("arrivalDate")} : ${dateFormat(createFormContext.value.formData.arrivalDate)}`}</span>
                        <span>{`${t("departurePlace")} : ${createFormContext.value.formData.departurePlace}`}</span>
                        <span>{`${t("arrivalPlace")} : ${createFormContext.value.formData.arrivalPlace}`}</span>
                        <span>{`${t("destination")} : ${createFormContext.value.formData.destination}`}</span>
                        <span>{`${t("departureVeterinary")} : ${createFormContext.value.formData.departureVeterinary}`}</span>
                        <span>{`${t("arrivalVeterinary")} (${t("optional")}) : ${createFormContext.value.formData.arrivalVeterinary}`}</span>
                        <span>{`${t("organiser")} : ${createFormContext.value.formData.organiser}`}</span>
                        <span>{`${t("species")} : ${createFormContext.value.formData.species}`}</span>
                        <span>{`${t("destiny")} : ${createFormContext.value.formData.destiny}`}</span>
                        <span>{`${t("state")} : ${createFormContext.value.formData.state}`}</span>
                        {createFormContext.value.formData.gotExit ? 
                            (<span>{`${t("gotExit")} : ${t("yes")}`}</span>) :
                            (<span>{`${t("gotExit")} : ${t("no")}`}</span>)
                        }
                        <span>{`${t("routes")} : ${createFormContext.value.formData.routes}`}</span>
                        <span>{`${t("transfer")} : ${createFormContext.value.formData.transfer}`}</span>
                    </Stack>
                </div>
                <div className="box">
                    <Stack horizontal>
                        <span className="titre">{t("contactsRecap")}</span>
                        <DefaultButton className="resetButton" onClick={backContacts}>{t("edit")}</DefaultButton>
                    </Stack>
                    <AgGrid
                        rowData={createFormContext.value.contacts}
                        columnDefs={columnDefsContacts}
                        onGridReady={handleOnGridReady}
                        suppressRowClickSelection={true}
                        pagination={true}
                        paginationAutoPageSize={true}
                    />
                </div>
                <div className="box">
                    <Stack>
                        <span className="titre">{t("messagesRecap")}</span>
                        <DefaultButton className="resetButton" onClick={backMessages}>{t("edit")}</DefaultButton>
                    </Stack>
                    <AgGrid
                        rowData={createFormContext.value.personalMessages}
                        columnDefs={columnDefsMessages}
                        onGridReady={handleOnGridReady}
                        suppressRowClickSelection={true}
                        pagination={true}
                        paginationAutoPageSize={true}
                    />
                </div>
            </Stack>
            <DefaultButton type="submit" className="submitButton" onClick={onSubmit}>{t("submit")}</DefaultButton>
        </Stack>
    )
}

export default memo(FormPage4)