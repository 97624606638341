import { DefaultButton, Icon, Stack } from "@fluentui/react"
import { memo, useState } from "react"
import { Link } from "react-router-dom"
import { EIconName } from "../../enums"
import ContactsControls from "./ContactsControls"
import ContactsDatatable from "./ContactsDatatable"
import { ContactsContext, defaultContactContext, IContactContextState } from "../../contexts"
import { useTranslation } from "react-i18next"
import "./Contacts.scss"

const ContactsPage = () => {

    const [state, setState] = useState<IContactContextState>(defaultContactContext.value);

    const { t } = useTranslation();
    
    return (
        <div className="app-contacts">
            <ContactsContext.Provider value={{value: state, setValue: setState}}>
                <Stack style={{height:"100%",width:"80%", marginLeft:"10%", marginRight:"5%"}}>
                    <Stack style={{display:"flex"}} horizontal>
                        <Link style={{alignSelf:"center", paddingLeft:"15%"}} to="/">
                            <DefaultButton className="quitButton">
                                <Icon iconName={EIconName.skypeArrow}/>
                                <span style={{paddingLeft:"10%"}}>{t("back")}</span>
                            </DefaultButton>   
                        </Link>
                        <h1 className="title" style={{alignSelf:"center", paddingLeft:"20%"}}>{t("contacts")}</h1>
                    </Stack>
                    <Stack style={{height:"100%", width:"100%"}}>
                        <ContactsControls/>
                        <ContactsDatatable/>
                    </Stack>
                </Stack>
            </ContactsContext.Provider>
        </div>
    )
}

export default memo(ContactsPage)