/** Enum of distinct status of http responses.*/
export enum EHttpResponseStatus {
    //#region Server side errors
    InternalServerError = 500,
    ServiceUnavailable = 503,
    NotImplemented = 501,
    //#endregion

    //#region Client side errors
    UnsupportedMediaType = 415,
    RequestTimeout = 408,
    NotAcceptable = 406,
    Unauthorized = 401,
    BadRequest = 400,
    Forbidden = 403,
    NotFound = 404,
    //#endregion

    //#region Technical error
    Request = -1,
    Network = 0,
    //#endregion

    //#region Success responses
    NoContent = 204,
    Created = 201,
    Ok = 200,
    //#endregion
}
