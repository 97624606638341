import httpRequestService from ".";
import { ELanguage } from "../../enums";
import { ICode, IContact, IHttpRequestServiceOptions, iInformations, IMessage, IPersonalMessage } from "../../interfaces";
import { INews } from "../../interfaces/iNews";


const welfarmService = {

    forms: {

        createForm: (data: iInformations, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<IMessage[]>('/forms', params, {
                data: {destiny: data.destiny,
                    species: data.species,
                    state: data.state,
                    destination: data.destination,
                    transfer: data.transfer,
                    routes: data.routes,
                    borderCrossed: data.borderCrossed,
                    gotExit: data.borderCrossed == 1,
                    language: data.language,
                    startMonth: data.departureDate.getMonth(),
                    endMonth: data.arrivalDate.getMonth()},
                method: 'POST',
            })
            .then(response => response.data),
    },

    contacts: {

        getContactsList: (id: number, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<IContact[]>(`/contacts`, params)
                .then(response => response.data),

        createContact: (data: IContact, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<number>('/create_contact', params, {
            data,
            method: 'POST',
        })
        .then(response => response.data),

        updateContact: (data: IContact, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<string>(`/update_contact`, params, {
                data,
                method: 'PUT',
                })
                .then(() => {}),

        removeContact: (id: number, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>(`/contacts/${id}`, params, {
                method: 'DELETE',
                })
                .then(() => {}),

        removeContacts: (idList: number[], params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>('/delete_contact', params, {
                    data: idList,
                    method: 'DELETE',
                })
                .then(() => {}),
    },

    codes: {
        getCodesList: (params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<ICode[]>('/codes', params)
            .then(response => response.data),

        createCode: (data: ICode, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<number>('/codes', params, {
            data,
            method: 'POST',
        })
        .then(response => response.data),
        
        updateCode: (data: ICode, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<string>(`/codes/${data.id}`, params, {
                data,
                method: 'PUT',
                })
                .then(() => {}),

        removeCode: (id: number, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>(`/codes/${id}`, params, {
                method: 'DELETE',
                })
                .then(() => {}),

        removeCodes: (idList: number[], params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>('/codes/', params, {
                    data: idList,
                    method: 'DELETE',
                })
                .then(() => {}),
        },

    messages: {
        getMessagesList: (language: ELanguage, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<IMessage[]>(`/messages/${language}`, params)
            .then(response => response.data),

        createMessage: (data: IMessage, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<number>('/messages', params, {
            data,
            method: 'POST',
        })
        .then(response => response.data),
        
        updateMessage: (data: IMessage, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<string>(`/messages/${data.id}`, params, {
                data,
                method: 'PUT',
                })
                .then(() => {}),

        removeMessage: (id: number, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>(`/messages/${id}`, params, {
                method: 'DELETE',
                })
                .then(() => {}),

        removeMessages: (idList: number[], params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>('/messages/?ids=' + idList.join('&ids='), params, {
                method: 'DELETE',
                })
                .then(() => {}),
        },

    personalMessages: {
        getPersonalMessagesList: (id: number, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<IPersonalMessage[]>(`/personalMessages`, params)
            .then(response => response.data),

        createPersonalMessage: (data: IPersonalMessage, params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<number>('/create_personalMessages', params, {
            data,
            method: 'POST',
        })
        .then(response => response.data),
        
        updatePersonalMessage: (data: IPersonalMessage, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<string>(`/update_personalMessages`, params, {
                data,
                method: 'PUT',
            })
            .then(() => {}),

        removePersonalMessage: (id: number, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>(`/personalMessages/${id}`, params, {
                method: 'DELETE',
            })
            .then(() => {}),

        removePersonalMessages: (idList: number[], params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>('/delete_personalMessages', params, {
                data: idList,
                method: 'DELETE',
            })
            .then(() => {}),
        },
    
    news: {
        getNews: (params: IHttpRequestServiceOptions = {}) =>
        httpRequestService
            .request<INews[]>(`/news`, params)
            .then(response => response.data),
    }
};

export default welfarmService;