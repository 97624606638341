import {DefaultButton, Icon, Stack} from "@fluentui/react"
import {
    CellClickedEvent,
    ColDef,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GridReadyEvent,
    ICellRendererParams,
    SelectionChangedEvent
} from "ag-grid-community";
import {memo, useContext, useEffect, useState} from "react"
import {Control, useForm} from "react-hook-form";
import {useTranslation, TFunction} from "react-i18next";
import {AgGrid} from "../../../components";
import LoadingView from "../../../components/loadingView";
import {ContactsContext, CreateFormContext, defaultContactContext, IContactContextState} from "../../../contexts";
import {EContactType, EIconName} from "../../../enums";
import {useHttpRequestStatus} from "../../../hooks";
import {IContact, IHttpRequestError} from "../../../interfaces";
import {alertService, formPanelService, httpRequestService, sessionService} from "../../../services";
import ContactsControls from "../../contactsPage/ContactsControls";
import ContactsDatatable from "../../contactsPage/ContactsDatatable";
import ContactsForm from "../../contactsPage/ContactsForm";
import "./FormPage2.scss"

const VARIABLES = {
    updateButton: (t: TFunction<'translation', undefined>) => {
        return (
            <div>
                <Icon title={t('edit')} role={'button'} iconName={EIconName.edit}/>
            </div>
        );
    },
    initFormData: {
        id: -1,
        name: "",
        address: "",
        postalCode: "",
        city: "",
        country: "",
        phone: "",
        mail: "",
        idUser: sessionService.user.get()?.id ?? "",
        contactType: EContactType.all
    } as IContact,
};

const FormPage2 = () => {

    const {t} = useTranslation();
    /** Context of parent component. */
    const [state, setState] = useState<IContactContextState>(defaultContactContext.value);

    const createFormContext = useContext(CreateFormContext);
    // /** Status of http request */
    const {isLoading} = useHttpRequestStatus<IContact[]>('getContacts', []);
    let rowSelected = false;

    /** Behavior when component is mounted and unmounted */
    useEffect(
        () => {
            /** Execute request */
            httpRequestService.welfarmService.contacts.getContactsList(sessionService.user.get()?.id ?? -1, {requestId: 'getContacts'}).then(response => {
                setState(l => ({
                    ...l,
                    rowsDataTable: response,
                }));
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /** Handler of event 'onGridReady'. */
    const handleOnGridReady = (e: GridReadyEvent) => {
        e.api.sizeColumnsToFit();
        setState((l: any) => ({
            ...l,
            gridApi: e.api,
        }));
    };

    const keepSelection = (e: FirstDataRenderedEvent) => {
        e.api.forEachNodeAfterFilter(node => {
            createFormContext.value.contacts.forEach(c => c.id == node.data.id ? node.setSelected(true) : null)
        });
    }

    const goBack = () => {
        createFormContext.setValue(l => ({...l, numPage: 1}))
    }

    const onSubmit = () => {
        if (state.gridApi?.getSelectedRows().length === 0) {
            alert(t('contactSelectionAlert'))
        } else {
            createFormContext.setValue(l => ({...l, contacts: state.gridApi?.getSelectedRows() ?? [], numPage: 3}))
        }
    }

    const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
        const rowDataSelected = event.api.getSelectedRows();
        rowSelected = rowDataSelected.length > 0;
        setState((l: any) => ({...l, rowDataSelected}));
    };

    const handleOnFilterChanged = (event: FilterChangedEvent<IContact>) => {
        if (state.gridApi?.getDisplayedRowCount() === 0 ?? true) {
            event.api.showNoRowsOverlay();
        } else {
            event.api.hideOverlay();
        }
    };

    const {
        reset: resetForm,
        control: controlForm,
        handleSubmit: handleSubmitForm,
        setError: setErrorForm,
        setValue: setValueForm,
    } = useForm<IContact>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnClickUpdateButton = (event: CellClickedEvent<IContact>) => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new contact
        setValueForm('id', event.data?.id ?? -1);
        setValueForm('name', event.data?.name ?? "");
        setValueForm('address', event.data?.address ?? "");
        setValueForm('postalCode', event.data?.postalCode ?? "");
        setValueForm('city', event.data?.city ?? "");
        setValueForm('country', event.data?.country ?? "");
        setValueForm('phone', event.data?.phone ?? "");
        setValueForm('mail', event.data?.mail ?? "");
        setValueForm('contactType', event.data?.contactType ?? EContactType.all)
        formPanelService.publish({
            content: <ContactsForm control={controlForm as Control<any>}/>,
            title: t("updateContact"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
                handleSubmitForm(
                    data =>
                        httpRequestService.welfarmService.contacts
                            .updateContact(data, {showErrorMessage: false})
                            .then(() => {
                                // Update in data table
                                setState(l => ({
                                    ...l,
                                    rowsDataTable: [...l.rowsDataTable.map(item => (item.id === data.id ? data : item))],
                                    rowDataSelected: [],
                                }));
                            })
                            .catch((error: IHttpRequestError) => {
                                //Update state of invalid field
                                Object.keys(error.Errors ?? {}).forEach(key => {
                                    const field = key.toLowerCase() as keyof IContact;
                                    setErrorForm(field, {
                                        message: 'Erreur : ' + error.Errors?.[key][0] ?? '',
                                    });
                                });
                                return Promise.reject();
                            }),
                    () => Promise.reject("invalid")
                )(),
        });
    };

    const columnDefs = [
        {
            checkboxSelection: true,
            suppressMovable: true,
            flex: 0.3,
            headerCheckboxSelection: true,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: t("name"),
            field: "name",
            suppressMovable: true,
            flex: 0.7,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("address"),
            field: "address",
            suppressMovable: true,
            flex: 1.3,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("postalCode"),
            field: "postalCode",
            suppressMovable: true,
            flex: 0.7,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("city"),
            field: "city",
            suppressMovable: true,
            flex: 0.8,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("country"),
            field: "country",
            suppressMovable: true,
            flex: 0.6,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("phone"),
            field: "phone",
            suppressMovable: true,
            flex: 0.8,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("mail"),
            field: "mail",
            suppressMovable: true,
            flex: 1.2,
            wrapText: true,
            autoHeight: true,
            cellStyle: {fontSize: "12px"}
        },
        {
            headerName: t("activity"),
            field: "contactType",
            suppressMovable: true,
            cellStyle: {fontSize: "12px"},
            cellRenderer: (props: ICellRendererParams<any, any>) => {
                return t(props.data.contactType)
            },
        },
        {
            suppressMovable: true,
            cellRenderer: () => VARIABLES.updateButton(t),
            onCellClicked: handleOnClickUpdateButton,
            flex: 0.2,
        },
    ] as ColDef[]

    return (
        <ContactsContext.Provider value={{value: state, setValue: setState}}>
            <Stack className="app-formPage2">
                <Stack style={{height: "10%", width: "100%", display: "flex", alignItems: "center"}} horizontal>
                    <h3 className="titre">{t("chooseContacts")}</h3>
                    <div style={{alignSelf: "center", paddingLeft: "53%"}}>
                        <ContactsControls/>
                    </div>
                </Stack>
                <AgGrid
                    onFilterChanged={handleOnFilterChanged}
                    rowData={state.rowsDataTable}
                    columnDefs={columnDefs}
                    onGridReady={handleOnGridReady}
                    rowSelection='multiple'
                    rowMultiSelectWithClick={true}
                    onSelectionChanged={handleOnSelectionChanged}
                    pagination={true}
                    paginationAutoPageSize={true}
                    onFirstDataRendered={keepSelection}
                />
                <Stack horizontal>
                    <DefaultButton className="resetButton" onClick={goBack}>
                        <Icon iconName={EIconName.skypeArrow}/>
                        <span style={{paddingLeft: "10%"}}> {t("back")}</span>
                    </DefaultButton>
                    <DefaultButton type="submit" onClick={onSubmit}
                                   className="submitButton">{t("contactsSubmit")}</DefaultButton>
                </Stack>
            </Stack>
        </ContactsContext.Provider>
    )
}

export default memo(FormPage2)