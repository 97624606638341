import { DefaultButton, FocusTrapZone, Icon, Layer, mergeStyleSets, Popup, Stack } from "@fluentui/react"
import { t } from "i18next"
import { memo, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CreateFormContext } from "../../../contexts"
import { EIconName } from "../../../enums"
import Header from "../../../layout/header"
import ChecklistPdf from "../../checklistPdf"
import ContingencyPlan from "../../contingencyPlan"
import "./FormPage5.scss"


const FormPage5 = () => {

    const { t } = useTranslation();

    const createFormContext = useContext(CreateFormContext);

    const popupStyles = mergeStyleSets({
        root: {
            background: 'rgba(0, 0, 0, 0.3)',
            bottom: '0',
            left: '0',
            position: 'fixed',
            right: '0',
            top: '0',
        },
        content: {
            background: 'white',
            padding: '0 2em 2em',
            position: 'absolute',
            left:"50%",
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },
    });

    return(
        <Stack className="app-formPage5">
            {createFormContext.value.showChecklist && (
            <Layer>
                <Popup
                className={popupStyles.root}
                enableAriaHiddenSiblings={true}>
                    <FocusTrapZone
                    className={popupStyles.content}>
                        <ChecklistPdf/>
                    </FocusTrapZone>
                </Popup>
            </Layer>)}
            {createFormContext.value.pdfDone && (
            <Layer>
                <Popup
                className={popupStyles.root}
                enableAriaHiddenSiblings={true}>
                    <FocusTrapZone
                    className={popupStyles.content}>
                        <ContingencyPlan/>
                    </FocusTrapZone>
                </Popup>
            </Layer>
            )}
            <h1 className="title">{t("recap")} :</h1>
            <h3 className="texte">{t("downloadTitle")} :</h3>
            <DefaultButton onClick={() => createFormContext.setValue(l => ({...l, pdfDone: true}))} className="resetButton">
                <Icon style={{marginRight: "3%", fontSize: "200%"}} iconName={EIconName.installation}/>
                {t("downloadButton")}
            </DefaultButton>
            <h3 className="texte" style={{paddingTop:"3%"}}>{t("checklistTitle")} :</h3>
            <DefaultButton onClick={() => createFormContext.setValue(l => ({...l, showChecklist: true}))} className="resetButton" style={{width:"43%"}}>
                <Icon style={{marginRight: "3%", fontSize: "200%"}} iconName={EIconName.installation}/>
                {t("downloadChecklistButton")}
            </DefaultButton>
        </Stack>
    )
}

export default memo(FormPage5)