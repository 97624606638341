

import { Checkbox, ICheckboxProps } from "@fluentui/react"
import { Controller } from "react-hook-form"
import { IHookFormProps } from "../../interfaces"

type TProps = IHookFormProps & ICheckboxProps

const ControlledCheckbox = (props: TProps) => (
    <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue || ""}
        render={() => (
            <Checkbox
                {...props}
            />
        )}
    />
)

export default ControlledCheckbox