import { Stack } from "@fluentui/react"
import { memo } from "react"
import { Control, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CCurrentUser } from "../../classes";
import { FormPanel, MessageBox } from "../../components";
import { IHttpRequestError, IRegister, IUserWithPassword } from "../../interfaces";
import { alertService, formPanelService } from "../../services";
import httpRequestService from "../../services/httpRequestService";
import sessionService from "../../services/sessionService"
import UserForm from "./UserForm";
import "./User.scss"


const VARIABLES = {
    initFormData: {
        id: sessionService.user.get()?.id ?? "",
        email: sessionService.user.get()?.email ?? "",
        phone: sessionService.user.get()?.phone ?? "",
        societyAddress: sessionService.user.get()?.societyAddress ?? "",
        society: sessionService.user.get()?.society ?? "",
        logo: sessionService.user.get()?.logo ?? "",
        password: "",
    } as IUserWithPassword,
};

const UserPage = () => {

    const { t } = useTranslation();

    const userConnected = sessionService.user.get();

    const {
        reset: resetForm,
        control: controlForm,
        handleSubmit: handleSubmitForm,
        setError: setErrorForm,
    } = useForm<IUserWithPassword>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });
    
    const changeProfile = () => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        formPanelService.publish({
            content: <UserForm control={controlForm as Control<any>} />,
            title: t("userUpdate"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
                handleSubmitForm(
                    data =>
                    httpRequestService.identityService.users
                        .updateUser(data, { showErrorMessage: false })
                        .then(() => {
                            const oldUser = sessionService.user.get()!
                            const currentUser = new CCurrentUser();
                            currentUser.email = oldUser.email;
                            currentUser.id = oldUser.id;
                            currentUser.password = data.password
                            currentUser.society = data.society
                            currentUser.societyAddress = data.societyAddress
                            currentUser.phone = data.phone
                            currentUser.logo = data.logo
                            sessionService.user.set(currentUser)
                        })
                        .catch((error: IHttpRequestError) => {
                        //Update state of invalid field
                        Object.keys(error.Errors ?? {}).forEach(key => {
                            const field = key.toLowerCase() as keyof IRegister;
                            setErrorForm(field, {
                            message: 'Erreur : ' + error.Errors?.[key][0] ?? '',
                            });
                        });
                        return Promise.reject();
                        }),
                    () => Promise.reject(t("invalid"))
                )(),
            });
    }

    return (
        <Stack className="app-user">
            <MessageBox/>
            <h1 className="title">{t("userPageTitle")}</h1>
            <Stack className="profile">
                <Stack className="zone" horizontal>
                    <span className="label">{t("mail")} : </span> 
                    <span className="info">{userConnected?.email}</span>
                </Stack>
                <Stack className="zone" horizontal>
                    <span className="label">{t("society")} : </span> 
                    <span className="info">{userConnected?.society}</span>
                </Stack>
                <Stack className="zone" horizontal>
                    <span className="label">{t("societyAddress")} : </span> 
                    <span className="info">{userConnected?.societyAddress}</span>
                </Stack>
                <Stack className="zone" horizontal>
                    <span className="label">{t("phone")} : </span> 
                    <span className="info">{userConnected?.phone}</span>
                </Stack>
                <Stack className="zone" horizontal>
                    <span className="label">{t("mail")} : </span> 
                    <span className="info">************</span>
                </Stack>
            </Stack>
            <button className="button" onClick={changeProfile}>{t("modify")}</button>
            <FormPanel/>
        </Stack>
    )
}

export default memo(UserPage)