import { IHttpRequestError } from '../interfaces';
import { EHttpResponseStatus } from '../enums';
import axios from 'axios';

axios.defaults.withCredentials = true

/** Global variables */
const VARIABLES = {
    /**
     * Throw promise for request error.
     * @param error Original error.
     */
    throwRequestError: (error: any) =>
        Promise.reject({
            Status: EHttpResponseStatus.Request,
            Detail: error.message,
            Title: error.message,
            Extensions: {},
            Type: "",
    } as IHttpRequestError),
    /** Throw promise for network error. */
    throwNetworkError: () =>
        Promise.reject({
            Status: EHttpResponseStatus.Network,
            Extensions: {},
            Detail: "",
            Title: "",
            Type: "",
    } as IHttpRequestError),
};

/** Instance axios. */
const axiosInstance = axios.create({
    //baseURL: `http://127.0.0.1:8000`,

    // Pour la prod
    // Un reverse proxi sur le serveur enlève "API" et redirect vers localhost:8000 du serveur

    // CONF NGINX
    //          location /api/ {
    //                 proxy_pass http://0.0.0.0:8000/;
    //         }
    baseURL: `https://plansdurgences.tnservices.fr/api`,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async config => {
        // Get access token
        const tokenResult = false
        // Create header object
        config.headers = {
            ...config.headers,
            'Content-Type': 'application/json',
            //'Accept-Language': lang,
            'Authorization': config.headers?.['Authorization']
                ? config.headers['Authorization']
                : "",
        };
        return config;
    },
    error => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // The request was made and the server responded with a status code
        if (error.response) {
            return Promise.reject(error.response.data as IHttpRequestError);
        }

        // The request was made but no response was received
        if (error.request) {
            return VARIABLES.throwNetworkError();
        }

        // Something happened while setting up the request that triggered an Error
        return VARIABLES.throwRequestError(error);
    }
);

export default axiosInstance;
