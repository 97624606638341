import { DefaultButton, Icon, Stack } from "@fluentui/react"
import { CellClickedEvent, ColDef, FilterChangedEvent, FirstDataRenderedEvent, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { memo, useContext, useEffect, useState } from "react"
import { Control, useForm } from "react-hook-form";
import { useTranslation, TFunction } from "react-i18next";
import { AgGrid } from "../../../components";
import LoadingView from "../../../components/loadingView";
import { EIconName } from "../../../enums";
import { useHttpRequestStatus } from "../../../hooks";
import { IContact, IHttpRequestError, IMessage, IPersonalMessage } from "../../../interfaces";
import { alertService, formPanelService, httpRequestService, sessionService } from "../../../services";
import { CreateFormContext, IPersonalMessageContextState, PersonalMessageContext, defaultPersonalMessageContext } from "../../../contexts";
import MessageForm from "../../personalMessagesPage/MessageForm";
import CustomMessageControls from "./CustomMessageControls";
import "./FormPage3.scss"

const VARIABLES = {
    updateButton: (t: TFunction<'translation', undefined>) => {
        return (
        <div className='ps-1'>
            <Icon title={t('edit')} role={'button'} iconName={EIconName.edit} />
        </div>
        );
    },
    initFormData: {
        id: -1,
        title: "",
        message: "",
        emergency: true,
        idUser: sessionService.user.get()?.id ?? "",
    } as IPersonalMessage,
};

const FormPage3 = () => {

    const { t } = useTranslation();

    const createFormContext = useContext(CreateFormContext);
    /** Context of parent component. */
    const [state, setState] = useState<IPersonalMessageContextState>(defaultPersonalMessageContext.value);
    // /** Status of http request */
    const { isLoading } = useHttpRequestStatus<IPersonalMessage[]>('getPersonalMessages', []);

    /** Behavior when component is mounted and unmounted */
    useEffect(
        () => {
            /** Execute request */
            httpRequestService.welfarmService.personalMessages.getPersonalMessagesList( sessionService.user.get()?.id ?? -1, {requestId: 'getPersonalMessages'} ).then(response => {
                setState(l => ({
                ...l,
                rowsDataTable: response,
            }));
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /** Handler of event 'onGridReady'. */
    const handleOnGridReady = (e: GridReadyEvent) => {
        e.api.sizeColumnsToFit();
        setState((l: any) => ({
            ...l,
            gridApi: e.api,
        }));
    };

    const keepSelection = (e : FirstDataRenderedEvent) => {
        e.api.forEachNodeAfterFilter(node => {
            createFormContext.value.personalMessages.forEach(c => c.id == node.data.id ? node.setSelected(true) : null)
        });
    }

    const goBack = () => {
        createFormContext.setValue(l => ({...l,numPage:2}))
    }
    
    const onSubmit = () => {
        createFormContext.setValue(l => ({...l, personalMessages: state.gridApi?.getSelectedRows() ?? [],numPage:4}))
    }

    const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
        const rowDataSelected = event.api.getSelectedRows();
        setState((l: any) => ({ ...l, rowDataSelected }));
    };

    const handleOnFilterChanged = (event: FilterChangedEvent<IPersonalMessage>) => {
    if (state.gridApi?.getDisplayedRowCount() === 0 ?? true) {
        event.api.showNoRowsOverlay();
    } else {
        event.api.hideOverlay();
    }
    };

    const {
        reset: resetForm,
        control: controlForm,
        handleSubmit: handleSubmitForm,
        setError: setErrorForm,
        setValue: setValueForm,
        } = useForm<IPersonalMessage>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnClickUpdateButton = (event: CellClickedEvent<IPersonalMessage>) => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new contact
        setValueForm('id', event.data?.id ?? -1);
        setValueForm('title', event.data?.title ?? "");
        setValueForm('message', event.data?.message ?? "");
        formPanelService.publish({
            content: <MessageForm control={controlForm as Control<any>} />,
            title: t("updatePersonalMessage"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
            handleSubmitForm(
                data =>
                httpRequestService.welfarmService.personalMessages
                    .updatePersonalMessage(data, { showErrorMessage: false })
                    .then(() => {
                    // Update in data table
                    setState(l => ({
                        ...l,
                        rowsDataTable: [...l.rowsDataTable.map(item => (item.id === data.id ? data : item))],
                        rowDataSelected: [],
                    }));
                    })
                    .catch((error: IHttpRequestError) => {
                    //Update state of invalid field
                    Object.keys(error.Errors ?? {}).forEach(key => {
                        const field = key.toLowerCase() as keyof IPersonalMessage;
                        setErrorForm(field, {
                        message: 'Erreur : ' + error.Errors?.[key][0] ?? '',
                        });
                    });
                    return Promise.reject();
                    }),
                () => Promise.reject("Formulaire invalide")
            )(),
        });
    };

    const columnDefs = [
        {
            checkboxSelection: true,
            suppressMovable: true,
            headerCheckboxSelection: true,
            flex:0.3,
        }, 
        {
            headerName: t("messageTitle"),
            field: "title",
            suppressMovable: true,
            flex: 1,
            wrapText: true,
            autoHeight:true,
        },
        {
            headerName: t("message"),
            field: "message",
            suppressMovable: true,
            flex: 1,
            wrapText: true,
            autoHeight:true,
        },
        {
            suppressMovable: true,
            cellRenderer: () => VARIABLES.updateButton(t),
            onCellClicked: handleOnClickUpdateButton,
            flex: 0.18,
        },
    ] as ColDef[]

    return(
        <PersonalMessageContext.Provider value={{value: state, setValue: setState}}>
            <Stack className="app-formPage3">
                <Stack style={{height:"10%", display: "flex", alignItems: "center"}} horizontal>
                    <h3 className="titre">{t("personalMessageTitle")}</h3>
                    <div style={{alignSelf:"center", paddingLeft:"25%"}}>
                        <CustomMessageControls/>
                    </div>
                </Stack>
                <AgGrid
                    onFilterChanged={handleOnFilterChanged}
                    rowData={state.rowsDataTable}
                    columnDefs={columnDefs}
                    onGridReady={handleOnGridReady}
                    rowSelection='multiple'
                    rowMultiSelectWithClick={true}
                    onSelectionChanged={handleOnSelectionChanged}
                    pagination={true}
                    paginationAutoPageSize={true}
                    onFirstDataRendered={keepSelection}
                />
                <Stack horizontal>
                    <DefaultButton className="resetButton" onClick={goBack}>
                        <Icon iconName={EIconName.skypeArrow}/>
                        <span style={{paddingLeft:"10%"}}> {t("back")}</span>
                    </DefaultButton>               
                    <DefaultButton type="submit" className="submitButton" onClick={onSubmit}>{t("bigSubmit")}</DefaultButton>
                </Stack>
            </Stack>
        </PersonalMessageContext.Provider>
    )
}

export default memo(FormPage3)