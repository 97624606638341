import { PrimaryButton, Stack } from '@fluentui/react';
import { Control, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MessageForm from './MessageForm';
import { memo, useContext } from 'react';
import { IPersonalMessage, IHttpRequestError } from '../../interfaces';
import { formPanelService, httpRequestService, messageBoxService, sessionService } from '../../services';
import { EIconName } from '../../enums';
import { FormPanel, MessageBox, QuickFilter } from '../../components';
import { PersonalMessageContext } from '../../contexts';
import "./PersonalMessages.scss"


const VARIABLES = {
    initFormData: {
        title: "",
        message: "",
        idUser: sessionService.user.get()?.id ?? -1,
    } as IPersonalMessage,
};

const PersonalMessagesControls = () => {
    /** Translation hook */
    const { t } = useTranslation();
    /** Context of parent component. */
    const personalMessagesContext = useContext(PersonalMessageContext);
    
    /** Hook to handle form data */
    const {
        reset: resetForm,
        control: controlForm,
        setError: setErrorForm,
        handleSubmit: handleSubmitForm,
    } = useForm<IPersonalMessage>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const handleOnChangeQuickFilter = (filterValue: string) => {
        personalMessagesContext.value.gridApi?.setQuickFilter(filterValue);
        personalMessagesContext.setValue(l => ({
            ...l,
            filterValue,
            numberRowsDisplayed: personalMessagesContext.value.gridApi?.getDisplayedRowCount() ?? 0,
        }));
    };

    /** Handler of event 'onClick' on button add personalMessage. */
    const handleOnClickAddPersonalMessage = () => {
        // reset data form
        resetForm(VARIABLES.initFormData);
        // Create new personalMessage
        formPanelService.publish({
            content: <MessageForm control={controlForm as Control<any>} />,
            title: t("addPersonalMessage"),
            cancelActionText: t("cancel"),
            confirmActionText: t("submit"),
            confirmActionCallBack: () =>
                handleSubmitForm(
                data =>
                    httpRequestService.welfarmService.personalMessages
                    .createPersonalMessage(data, { showErrorMessage: false })
                    .then((id) => {
                        data.id = id
                        // Add in data table
                        personalMessagesContext.setValue(l => ({
                            ...l,
                            rowsDataTable: [...l.rowsDataTable, data],
                        }));
                        // Reselect nodes
                        personalMessagesContext.value.gridApi?.forEachNode(node =>
                        personalMessagesContext.value.rowDataSelected.includes(node.data)
                            ? node.setSelected(true)
                            : node.setSelected(false)
                        );
                    })
                    .catch((error: IHttpRequestError) => {
                        //Update state of invalid field
                        Object.keys(error.Errors ?? {}).forEach(key => {
                        setErrorForm(key.toLowerCase() as keyof IPersonalMessage, {
                            message: t("Erreur : " + error.Errors?.[key][0] ?? ''),
                        });
                        });
                        return Promise.reject();
                    }),
                () => Promise.reject(t('invalid'))
            )(),
        });
    };

    /** Handler of event 'onClick' on button remove personalMessages. */
    const handleOnClickDelete = () => {
        messageBoxService.confirm(
        t("sure"),
        () =>
            new Promise((resolve, reject) => {
            // Get data to delete
            const selectedRowIds = personalMessagesContext.value.gridApi?.getSelectedRows().map(l => (l as IPersonalMessage).id) ?? [];
                // Execute http request
            httpRequestService.welfarmService.personalMessages
                .removePersonalMessages(selectedRowIds, { showErrorMessage: false })
                .catch((error: any) => reject(error));
            // Remove from data table
            personalMessagesContext.setValue(l => ({
                ...l,
                rowsDataTable: [...l.rowsDataTable.filter(item => !selectedRowIds.includes(item.id))],
                rowDataSelected: [],
            }));
            // Resolve promise
            resolve();
            })
        );
    };

    return (
        <Stack className="personalMessage-controls" horizontal>
            <FormPanel/>
            <MessageBox/>
            <Stack horizontal>
                {personalMessagesContext.value.rowDataSelected.some(_ => true) && (
                <PrimaryButton
                    className="deleteButton"
                    iconProps={{ iconName: EIconName.delete }}
                    onClick={handleOnClickDelete}
                    text={t("remove")}
                />
                )}
                <PrimaryButton
                    className="addButton"
                    iconProps={{ iconName: EIconName.add }}
                    text={t("add")}
                    onClick={handleOnClickAddPersonalMessage}
                />
                <QuickFilter value={personalMessagesContext.value.filterValue} onChange={handleOnChangeQuickFilter} />
            </Stack>
        </Stack>
    );
};

export default memo(PersonalMessagesControls);
