import { Stack } from "@fluentui/react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { memo, ReactNode, useContext } from "react"
import { useTranslation } from "react-i18next";
import { CreateFormContext } from "../../contexts";
import FormPage1 from "../formPages/formPage1";
import FormPage2 from "../formPages/formPage2";
import FormPage3 from "../formPages/formPage3";
import FormPage4 from "../formPages/formPage4";
import FormPage5 from "../formPages/formPage5";
import "./CreateFormPage.scss"


const CreateFormPage = () => {

    const createFormContext = useContext(CreateFormContext);

    const { t } = useTranslation();

    const steps = ['','','','',''];

    const formComponent = () => {
        switch(createFormContext.value.numPage){

            case 1:
                return <FormPage1/>
            case 2:
                return <FormPage2/>
            case 3:
                return <FormPage3/>
            case 4:
                return <FormPage4/>
            case 5:
                return <FormPage5/>

        }
    }
    return(
        <div className="app-createForm">
            <Stack className="header">
                <h1 className="title">{t("editPlan")}</h1>
                <Box sx={{ width: '100%' }}>
                    <Stepper className="stepper" activeStep={createFormContext.value.numPage - 1}>
                        {steps.map((label, _) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: ReactNode;
                        } = {};
                        return (
                            <Step {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                </Box>
            </Stack>
            {formComponent()}
        </div>
    )
}

export default memo(CreateFormPage)