import { IFormPanelOptions } from '../interfaces';
import { Subject } from 'rxjs';

/** Global variables */
const VARIABLES = {
    /** Subject of service 'formPanel'. */
    subject: new Subject<IFormPanelOptions>(),
};

/** Service used to spread config to form component. */
const formPanelService = {
    /**
     * Publish options in form panel.
     * @param options Options of panel to display.
     */
    publish: (options: IFormPanelOptions) => VARIABLES.subject.next(options),
    /**
     * Get options of form panel published.
     */
    getOptions: () => VARIABLES.subject.asObservable(),
};

export default formPanelService;
