import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, IModalProps, Stack } from '@fluentui/react';
import { EIconName, EMessageBoxType } from '../../enums';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { messageBoxService } from '../../services';
import { IHasIsLoading } from '../../interfaces';
import { TFunction } from 'i18next';
import './MessageBox.scss';

/** Interface of state */
interface IState extends IHasIsLoading {
    confirmActionFallback?: () => Promise<void>;
    confirmActionText: string;
    cancelActionText: string;
    type: EMessageBoxType;
    isHidden: boolean;
    content: string;
    title?: string;
}

/** Global variables */
const VARIABLES = {
    /** Icon name displayed according type of message box. */
    iconName: (type: EMessageBoxType) => {
        switch (type) {
        case EMessageBoxType.question:
            return EIconName.unknown;

        case EMessageBoxType.info:
            return EIconName.info;

        case EMessageBoxType.error:
            return EIconName.errorBadge;

        case EMessageBoxType.warning:
            return EIconName.warning;

        default:
            return EIconName.completed;
        }
    },
    /** Classname applied on title of message box according to his type. */
    classNameTitle: (type: EMessageBoxType) => {
        switch (type) {
        case EMessageBoxType.success:
            return 'text-green';

        case EMessageBoxType.error:
            return 'text-red';

        case EMessageBoxType.warning:
            return 'text-yellow';

        case EMessageBoxType.info:
            return 'text-blue';

        default:
            return "";
        }
    },
    /** Title of message box displayed according to type of message. */
    title: (state: IState, t: TFunction) => {
        if (state.title) {
        return state.title;
        } else {
        switch (state.type) {
            case EMessageBoxType.question:
            return t("sure");

            case EMessageBoxType.info:
            return t("information");;

            case EMessageBoxType.error:
            return t("error");;

            case EMessageBoxType.warning:
            return t("warning");;

            default:
            return t("done");;
        }
        }
    },
    modalProps: {
        isBlocking: true,
        className: 'app-messageBox',
    } as IModalProps,
    initState: {
        confirmActionFallback: undefined,
        confirmActionText: "",
        cancelActionText: "",
        isLoading: false,
        isHidden: true,
        content: "",
    } as IState,
};

/**
 * Component used to display message box confirmation in application.
 * @returns JSX.Element.
 */
const MessageBox = () => {
    /** Translation hook */
    const { t } = useTranslation();
    // State's accessers of component.
    const [state, setState] = useState<IState>(VARIABLES.initState);

    // Behavior when component is mounted and unmounted
    useEffect(
        () => {
        // Subscription to alert service
        const subscription = messageBoxService.getMessages().subscribe(message => {
            // Update state
            setState(l => ({
            ...l,
            confirmActionFallback: message.options?.confirmActionCallBack,
            confirmActionText: message.options?.confirmActionText ?? t("ok"),
            cancelActionText: message.options?.cancelActionText ?? t("cancel"),
            title: message.options?.title,
            content: message.subject,
            type: message.type,
            isLoading: false,
            isHidden: false,
            }));
        });
        // When component is unmounted
        return () => subscription.unsubscribe();
        },
        // eslint-disable-next-line
        []
    );

    /** Handler of event 'onDismiss' in component 'Dialog'. */
    const handleOnDismiss = () => setState(l => ({ ...l, isHidden: true }));

    /** Handler of event 'onClick' on button 'Confirm action'. */
    const handleOnClickConfirm = () => {
        // Check if callback function has been defined
        if (state.confirmActionFallback) {
        // Update state
        setState(l => ({ ...l, isLoading: true }));
        // Execute confirm action call back
        state
            .confirmActionFallback?.()
            .finally(() => setState(l => ({ ...l, isHidden: true, isLoading: false })));
        } else {
        // Update state
        setState(l => ({ ...l, isHidden: true }));
        }
    };

    return (
        <Dialog
        modalProps={VARIABLES.modalProps}
        onDismiss={handleOnDismiss}
        hidden={state.isHidden}
        dialogContentProps={{
            type: DialogType.normal,
            title: (
            <Stack className={VARIABLES.classNameTitle(state.type)} horizontal verticalAlign='center'>
                <Icon style={{marginTop:"1%", marginRight:"2%"}} iconName={VARIABLES.iconName(state.type)} />
                {VARIABLES.title(state, t)}
            </Stack>
            ),
            subText: state.content,
        }}
        >
        <DialogFooter>
            <DefaultButton
            primary
            disabled={state.isLoading}
            onClick={handleOnClickConfirm}
            text={state.confirmActionText}
            />
            {state.confirmActionFallback && (
            <DefaultButton disabled={state.isLoading} onClick={handleOnDismiss} text={state.cancelActionText} />
            )}
        </DialogFooter>
        </Dialog>
    );
};

export default memo(MessageBox);
