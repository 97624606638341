import {
  CellClickedEvent,
  ColDef,
  FilterChangedEvent,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { alertService, formPanelService, httpRequestService, sessionService } from '../../services';
import { TFunction, useTranslation } from 'react-i18next';
import { IHttpRequestError, IContact } from '../../interfaces';
import { memo, useContext, useEffect } from 'react';
import { Control, useForm } from 'react-hook-form';
import ContactsForm from './ContactsForm';
import { Icon } from '@fluentui/react';
import { EContactType, EIconName } from '../../enums';
import AgGrid from '../../components/agGrid';
import { useHttpRequestStatus } from '../../hooks';
import LoadingView from '../../components/loadingView';
import { ContactsContext } from '../../contexts';
import { Button } from 'react-bootstrap';

/** Global variables */
const VARIABLES = {
  updateButton: (t: TFunction<'translation', undefined>) => {
    return (
      <div className='ps-1'>
        <Icon title={t('edit')} role={'button'} iconName={EIconName.edit} />
      </div>
    );
  },
  initFormData: {
    id: -1,
    name: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    phone: "",
    mail: "",
    idUser: sessionService.user.get()?.id ?? "",
    contactType: EContactType.all
  } as IContact,
};

const ContactsDataTable = () => {
  /** Translation hook */
  const { t } = useTranslation();
  /** Context of parent component. */
  const contactsContext = useContext(ContactsContext);
  // /** Status of http request */
  const { isLoading } = useHttpRequestStatus<IContact[]>('getContacts', []);

  /** Behavior when component is mounted and unmounted */
  useEffect(
    () => {
      /** Execute request */
      httpRequestService.welfarmService.contacts.getContactsList( sessionService.user.get()!.id, {requestId: 'getContacts'} ).then(response => {
        contactsContext.setValue(l => ({
          ...l,
          rowsDataTable: response,
        }));
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /** Handler of event 'onGridReady'. */
  const handleOnGridReady = (e: GridReadyEvent) => {
    e.api.sizeColumnsToFit();
    contactsContext.setValue((l: any) => ({
      ...l,
      gridApi: e.api,
      numberRowsDisplayed: e.api.getDisplayedRowCount(),
    }));
  };

  const handleOnSelectionChanged = (event: SelectionChangedEvent) => {
    const rowDataSelected = event.api.getSelectedRows();
    contactsContext.setValue((l: any) => ({ ...l, rowDataSelected }));
  };

  const handleOnFilterChanged = (event: FilterChangedEvent<IContact>) => {
    if (contactsContext.value.gridApi?.getDisplayedRowCount() === 0 ?? true) {
      event.api.showNoRowsOverlay();
    } else {
      event.api.hideOverlay();
    }
  };

  const {
    reset: resetForm,
    control: controlForm,
    handleSubmit: handleSubmitForm,
    setError: setErrorForm,
    setValue: setValueForm,
  } = useForm<IContact>({
    defaultValues: VARIABLES.initFormData,
    reValidateMode: 'onBlur',
    mode: 'all',
  });

  const handleOnClickUpdateButton = (event: CellClickedEvent<IContact>) => {
    // reset data form
    resetForm(VARIABLES.initFormData);
    // Create new contact
    setValueForm('id', event.data?.id ?? -1);
    setValueForm('name', event.data?.name ?? "");
    setValueForm('address', event.data?.address ?? "");
    setValueForm('postalCode', event.data?.postalCode ?? "");
    setValueForm('city', event.data?.city ?? "");
    setValueForm('country', event.data?.country ?? "");
    setValueForm('phone', event.data?.phone ?? "");
    setValueForm('mail', event.data?.mail ?? "");
    setValueForm('contactType', event.data?.contactType ?? EContactType.all)
    formPanelService.publish({
      content: <ContactsForm control={controlForm as Control<any>} />,
      title: t("updateContact"),
      cancelActionText: t("cancel"),
      confirmActionText: t("submit"),
      confirmActionCallBack: () =>
        handleSubmitForm(
          data =>
            httpRequestService.welfarmService.contacts
              .updateContact(data, { showErrorMessage: false })
              .then(() => {
                // Update in data table
                contactsContext.setValue(l => ({
                  ...l,
                  rowsDataTable: [...l.rowsDataTable.map(item => (item.id === data.id ? data : item))],
                  rowDataSelected: [],
                }));
              })
              .catch((error: IHttpRequestError) => {
                //Update state of invalid field
                Object.keys(error.Errors ?? {}).forEach(key => {
                  const field = key.toLowerCase() as keyof IContact;
                  setErrorForm(field, {
                    message: 'Erreur : ' + error.Errors?.[key][0] ?? '',
                  });
                });
                return Promise.reject();
              }),
          () => Promise.reject(t("invalid"))
        )(),
    });
  };

  const columnDefs = [
    {
        headerName: t("name"),
        field: "name",
        suppressMovable: true,
        flex:0.7,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
        headerName: t("address"),
        field: "address",
        suppressMovable: true,
        flex:1.3,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
        headerName: t("postalCode"),
        field: "postalCode",
        suppressMovable: true,
        flex:0.7,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
        headerName: t("city"),
        field: "city",
        suppressMovable: true,
        flex:0.8,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
        headerName: t("country"),
        field: "country",
        suppressMovable: true,
        flex:0.6,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
        headerName: t("phone"),
        field: "phone",
        suppressMovable: true,
        flex:0.8,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
        headerName: t("mail"),
        field: "mail",
        suppressMovable: true,
        flex:1.2,
        wrapText: true,
        autoHeight:true,
        cellStyle: {fontSize: "12px"}
    },
    {
      headerName: t("activity"),
      field: "contactType",
      suppressMovable: true,
      cellStyle: {fontSize: "12px"},
      cellRenderer: (props: ICellRendererParams<any, any>) => {
        return t(props.data.contactType)
      },
    },
    {
        suppressMovable: true,
        cellRenderer: () => VARIABLES.updateButton(t),
        onCellClicked: handleOnClickUpdateButton,
        flex:0.2,
    },   
  ] as ColDef[]

  return (
    <AgGrid
      onFilterChanged={handleOnFilterChanged}
      rowData={contactsContext.value.rowsDataTable}
      columnDefs={columnDefs}
      onGridReady={handleOnGridReady}
      rowSelection='multiple'
      rowMultiSelectWithClick={true}
      onSelectionChanged={handleOnSelectionChanged}
      pagination={true}
      paginationAutoPageSize={true}
    />
  );
};

export default memo(ContactsDataTable);
