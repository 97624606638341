import { Control } from 'react-hook-form';
import { Stack } from '@fluentui/react';
import { memo } from 'react';
import { ControlledTextField } from '../../components';
import { useTranslation } from 'react-i18next';

/** Interface of component's props */
interface IProps {
    control: Control<any>;
}

const UserForm = ({ control }: IProps) => {

    const { t } = useTranslation();

    return (
        <Stack>
        <ControlledTextField
            control={control}
            className='mb-2'
            name="email"
            rules={{ required: t("required") }}
            disabled={true}
            label={t("mail")}
            maxLength={100}
        />
        <ControlledTextField
            control={control}
            name="password"
            disabled={control._formState.isSubmitting}
            label={t("password")}
            placeholder="***********"
            maxLength={100}
        />
        <ControlledTextField
            control={control}
            name="society"
            disabled={control._formState.isSubmitting}
            label={t("society")}
            maxLength={100}
        />
        <ControlledTextField
            control={control}
            name="societyAddress"
            disabled={control._formState.isSubmitting}
            label={t("societyAddress")}
            maxLength={100}
        />
        <ControlledTextField
            control={control}
            name="phone"
            disabled={control._formState.isSubmitting}
            label={t("phone")}
            maxLength={100}
        />
        </Stack>
    );
};

export default memo(UserForm);
