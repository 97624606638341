import { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        paddingTop:"2%",
        borderBottom: 1,
        borderColor: "grey",
        paddingBottom:"2%"
    },
    titre: {
        width: "30%",
        paddingRight:"5%",
        fontFamily: "Open Sans", 
        fontWeight: 700,
        fontSize: "10px",
    },
    description: {
        width: "70%",
        fontSize: "10px",
    },
});

const TableRow = ({ items } : any) => {
    const rows = items.map((item : any) => (
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.titre}>{item.titre}</Text>
            <Text style={styles.description}>{item.description}</Text>
        </View>
    ));
    return <Fragment>{rows}</Fragment>;
};

export default TableRow;