import { IAlertOptions, IAlert, IAlertCommand } from '../interfaces';
import { MessageBarType } from '@fluentui/react';
import { nanoid } from 'nanoid';
import { Subject } from 'rxjs';

/** Global variables */
const VARIABLES = {
    /** Subject of service 'alert'. */
    subject: new Subject<IAlertCommand>(),
};

/** Service used to spread notifications in application. */
const alertService = {
    /**
     * Command to alert informations.
     * @param message Message to display on alert.
     * @param options Option to apply on alert.
     */
    info: (message: string, options?: IAlertOptions) =>
        alertService.alert(MessageBarType.info, message, options),
    /**
     * Command to alert errors.
     * @param message Message to display on alert.
     * @param options Option to apply on alert.
     *  */
    error: (message: string, options?: IAlertOptions) =>
        alertService.alert(MessageBarType.error, message, options),
    /**
     * Command to alert warning.
     * @param message Message to display on alert.
     * @param options Option to apply on alert.
     *  */
    warning: (message: string, options?: IAlertOptions) =>
        alertService.alert(MessageBarType.warning, message, options),
    /**
     * Command to alert success.
     * @param message Message to display on alert.
     * @param options Option to apply on alert.
     *  */
    success: (message: string, options?: IAlertOptions) =>
        alertService.alert(MessageBarType.success, message, options),
    /**
     * Command to alert.
     * @param type Type of alert displayed.
     * @param message Message to display on alert.
     * @param options Option to apply on alert.
     *  */
    alert: (type: MessageBarType, message: string, options?: IAlertOptions) => {
        // Generate id for new alert
        const id = nanoid();
        // Data of command published
        const data = {
            id,
            type,
            message,
            isKeptOnAllPage: options?.isKeptOnAllPage ?? false,
            isAutoClose: options?.isAutoClose ?? true,
            isClosable: options?.isClosable ?? true,
            ms: options?.ms ?? 3000,
        } as IAlert;
        // Publish alert generated
        VARIABLES.subject.next({ data, type: 'add' });

        return id;
    },
    /** Command to clear all notifications displayed. */
    clearAlerts: (id?: string) =>
        VARIABLES.subject.next({ data: id as string, type: id ? 'remove' : 'clearAll' }),
    /** Command to get all current notification. */
    getAlerts: () => VARIABLES.subject.asObservable(),
};

export default alertService;
