
import { ERole } from '../enums';
import { IHasNumberId, IRole } from '../interfaces';

export class CCurrentUser implements IHasNumberId {
    email: string;
    password: string;
    roles: IRole[];
    id: number;
    society: string;
    societyAddress: string;
    logo?: string;
    phone: string;

    constructor() {
        this.email = "";
        this.password  = "";
        this.roles = [];
        this.id = -1;
        this.society = "";
        this.societyAddress = "";
        this.phone = "";
    }

    isAdmin = () => this.roles.some(l => l.code === ERole.ADMIN);
}
