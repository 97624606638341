import { memo, useState } from 'react';
import { EIconName } from '../../enums';
import { Icon, Stack } from '@fluentui/react';
import { t } from 'i18next';
import './QuickFilter.scss';

/** Global variables */
const VARIABLES = {
    initValue: (initValue?: string) =>
        ({
        clearFilterHasFocus: false,
        value: initValue ?? "",
        } as IState),
};

/**Interface of state */
interface IState {
    clearFilterHasFocus: boolean;
    value: string;
}

/**Interface of component's props */
interface IProps {
    onChange?: (value: string) => void;
    onReset?: () => void;
    value?: string;
    width?: number;
}

/**
 * Component used to display a filter.
 * @returns JSX.Element.
 */
const QuickFilter = ({ value = "", width = 300, onChange, onReset }: IProps) => {
    // State of component
    const [state, setState] = useState<IState>(VARIABLES.initValue(value));

    /** Handler of event 'onChange'. */
    const handleOnChange = (event: any) => {
        // Get value
        const newValue = event?.currentTarget.value ?? "";
        // Update state
        setState(l => ({ ...l, value: newValue }));
        // Execute callback
        onChange?.(newValue);
    };

    /** Handler of event 'onClick'. */
    const handleOnClickResetButton = () => {
        // Update state
        setState(l => ({ ...l, value: "" }));
        // Execute callback functions
        onChange?.("");
        onReset?.();
    };

    return (
        <Stack
            className='app-QuickFilter'
            horizontal={true}
            verticalAlign='center'
            style={{ maxWidth: width }}
            >
            <Icon className='ms-fontColor-gray90' iconName={EIconName.filter} />
            <input
                placeholder={`${t("filter")}...`}
                value={state.value}
                style={{width:"100%"}}
                onChange={handleOnChange}
            />
            <Icon
                title={t('clear')}
                role={'button'}
                onMouseEnter={() => setState(l => ({ ...l, clearFilterHasFocus: true }))}
                onMouseLeave={() => setState(l => ({ ...l, clearFilterHasFocus: false }))}
                onClick={handleOnClickResetButton}
                style={{paddingLeft:"1%", paddingRight:"1%"}}
                className={` ${state.clearFilterHasFocus ? 'ms-fontColor-gray100' : 'ms-fontColor-gray60'}`}
                iconName={EIconName.clear}
            />
        </Stack>
    );
};

export default memo(QuickFilter);
