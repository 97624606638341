import { CommandBarButton, Dropdown, IDropdown, IDropdownOption, IDropdownStyles, Stack  } from "@fluentui/react"
import { createRef, FormEvent, memo, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { changeLanguage } from "../../config/i18n"
import { CreateFormContext } from "../../contexts"
import { ELanguage } from "../../enums"
import {httpRequestService, sessionService} from "../../services"
import "./Header.scss"


const Header = () => {

    const { t } = useTranslation();

    const createFormContext = useContext(CreateFormContext);

    const menuOptions: IDropdownOption[] = [
        {key: "personalSpace", text: t("personalSpace"), hidden: true},
        {key: "profile", text: t("profileHeader")},
        {key: "contacts", text: t("myContacts")},
        // {key: "archives", text: t("archives")},
        {key: "personalMessages", text: t("personalMessageHeader")},
        {key: "disconnect", text: t("disconnect")},
    ]

    const dropdownStyles: Partial<IDropdownStyles> = {
        caretDownWrapper: {
            width:"0px",
            height:"0px",
        },
        caretDown: {
            width:"0px",
            height:"0px",
            color: "transparent"
        },
        dropdownItem: {
                backgroundColor: "white",
                color: "#07366E",
                fontWeight: "900",
                fontFamily: "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
                cursor: "pointer"
            },
        };

    const dropdownRef = createRef<IDropdown>();
    const onSetFocus = () => {
        dropdownRef.current!.focus(true);
    }
    const unsetFocus = () => {
        //dropdownRef.current!.dismissMenu();
    }

    const onChange = (event: FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        if (item.key == "disconnect"){
            window.location.href = "/"
            sessionService.user.set(null)
            httpRequestService.request('/logout', {}, {
                method: "POST",
            }).then(r => {
                console.log(r)
            })
        }
        else{
            window.location.href = "/" + item.key
        }
        
    };

    if (!sessionService.user.get()){
        return(
            <div className="app-Header">
                <div className="rectangle">
                    <div className="hautRectangle">
                        
                        <img onClick={() => changeLanguage(ELanguage.fr)} className="drapeau" src={require("../../assets/images/Groupe6@2x.png")}/>
                        <img onClick={() => changeLanguage(ELanguage.en)} className="drapeau" src={require("../../assets/images/united-kingdom-2@2x.png")}/>
                        
                        <Link className="carre" to="/register">
                            <CommandBarButton className="button"
                            >{t('register')}</CommandBarButton>
                        </Link>
                        <Link className="carre" to="/login">
                            <CommandBarButton className="button">
                                {t('login')}</CommandBarButton>
                        </Link>
                    </div>
                    <Link to="/">
                        <img className="logo" src={require("../../assets/images/logo.png")} alt="Logo"/>
                    </Link>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className="app-Header">
                <div className="rectangle">
                    <div className="hautRectangle">
                        
                        <img onClick={() => changeLanguage(ELanguage.fr)} className="drapeau" src={require("../../assets/images/Groupe6@2x.png")}/>
                        <img onClick={() => changeLanguage(ELanguage.en)} className="drapeau" src={require("../../assets/images/united-kingdom-2@2x.png")}/>
                        
                        <Link className="carre" to="/contacts">
                            <CommandBarButton className="button"
                            >{t('updateContactHeader')}</CommandBarButton>
                        </Link>
                        <Link className="carre" to="/form">
                            <CommandBarButton onClick={() => createFormContext.setValue(l => ({...l, numPage:1}))} className="button"
                            >{t('createPlan')}</CommandBarButton>
                        </Link>
                        <Stack className="dropdown" horizontal>
                            {/*<img style={{width:"20%", paddingRight:"2%"}}src={require("../../assets/images/account@2x.png")}/>*/}
                            <Dropdown
                                className="content"
                                selectedKey={"personalSpace"}
                                onChange={(_e, option) => {
                                    onChange(_e, option!)
                                }}
                                styles={dropdownStyles}
                                componentRef={dropdownRef}
                                onMouseEnter={onSetFocus}
                                onMouseLeave={unsetFocus}
                                disabled={true}
                                placeholder={t('personalSpace')}
                                options={menuOptions}/>
                        </Stack>
                    </div>
                    <Link to="/">
                        <img className="logo" src={require("../../assets/images/logo.png")}/>
                    </Link>
                </div>
            </div>
        )
    }
}

export default memo(Header)