import { DefaultButton, Stack } from "@fluentui/react"
import { memo } from "react"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CCurrentUser } from "../../classes";
import { MessageBox } from "../../components";
import ControlledTextField from "../../components/controlledTextField"
import { IHttpRequestError, ILogin } from "../../interfaces";
import { messageBoxService, sessionService } from "../../services";
import httpRequestService from "../../services/httpRequestService";
import "./LoginPage.scss"

const VARIABLES = {
    initFormData: {
        email: "",
        password: "",
    } as ILogin,
};

const LoginPage = () => {

    const { t } = useTranslation();

    const {
        control,
        handleSubmit,
        setError,
    } = useForm<ILogin>({
        defaultValues: VARIABLES.initFormData,
        reValidateMode: 'onBlur',
        mode: 'all',
    });

    const onSubmit = (data: ILogin) => {
        httpRequestService.identityService.users
        .userConnect(data, {showErrorMessage: false})
            .then((d) => {
                const currentUser = new CCurrentUser();
                currentUser.email = d.email;
                currentUser.id = d.id;
                currentUser.password = d.password
                currentUser.phone = d.phone
                currentUser.society = d.society
                currentUser.societyAddress = d.societyAddress
                currentUser.logo = d.logo
                sessionService.user.set(currentUser)
                window.location.href = "/"
            })
            .catch((error: IHttpRequestError) => {
                console.log(error)
                setError("password", {
                    message: "Email/Mot de passe incorrect",
                });
                return Promise.reject()
            })
    }

    return(
        <div className="app-login">
            <Stack>
                <MessageBox/>
                <span className="title">{t("personalSpace")}</span>
                <br/>
                <div className="zone">
                    <span className="subTitle">{t("personalSpaceLogin")}</span>
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <Stack className="stack" horizontal>
                            <span className="label">{t("mail")}</span>
                            <ControlledTextField
                                className="textfield"
                                control={control}
                                name="email"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        <Stack className="stack" horizontal>
                            <span className="label">{t("password")}</span>
                            <ControlledTextField
                                className="textfield"
                                control={control}
                                type="password"
                                name="password"
                                rules={{required: t("required")}}
                                maxLength={100}
                            />
                        </Stack>
                        {/* <span className="textForget">{t("forgot")}</span> */}
                        <DefaultButton className="submitButton" type="submit">{t("submit")}</DefaultButton>
                    </form>
                </div>
            </Stack>
        </div>
    )
}

export default memo(LoginPage)