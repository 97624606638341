import { SpinButton, ISpinButtonStyles, ISpinButtonProps } from '@fluentui/react/lib/SpinButton';
import { memo, SyntheticEvent, useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { IHookFormProps } from '../../interfaces';

type TProps = IHookFormProps & ISpinButtonProps

const ControlledSpinbutton = (props: TProps) => {

    return (
        <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.defaultValue || 0}
        render={({
            field: {onChange, value}
        }) => (
            <SpinButton
                {...props}
                value={value}
                min={0}
                step={1}
                onChange={(_e, newValue) => {
                    onChange(newValue)
                }}
            />
        )}
    />
    );
};

export default memo(ControlledSpinbutton)
