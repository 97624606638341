import { ColDef, GridReadyEvent } from "ag-grid-community"
import { AgGridReact, AgGridReactProps, AgReactUiProps } from "ag-grid-react"
import { memo } from "react"
import './AgGrid.scss'

interface IProps extends AgGridReactProps, AgReactUiProps {
}

const VARIABLES = {
    rowHeight: 40,
    defaultColDef: {
        headerComponentParams: {
        menuIcon: 'fa-bars',
        },
        sortable: true,
    } as ColDef
};

const AgGrid = (props : IProps) => {

    const handleOnGridReady = (event: GridReadyEvent) =>{
        props.onGridReady?.(event)
    }

    return(
        <div className="app-agGrid">
            <AgGridReact
                {...{
                    ...props,
                    defaultColDef: Object.assign({}, VARIABLES.defaultColDef, props.defaultColDef),
                    onGridReady: handleOnGridReady,
                    className: `ag-theme-alpine ${props.className}`,
                    headerHeight: VARIABLES.rowHeight,
                    rowHeight: VARIABLES.rowHeight
                }}
            />
        </div>
    )
}

export default memo(AgGrid)