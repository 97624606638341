
import httpRequestService from '.';
import { IEmail, IHttpRequestServiceOptions, ILogin, IRegister, IUser, IUserWithPassword } from '../../interfaces';
import { IUserWithToken } from '../../interfaces/iUserWithToken';

/** Object used to access to module 'Identity' on server. */
const identityService = {
  /** Resource users. */
    users: {

        userConnect: (data: ILogin, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<IUserWithToken>('/login', params, {
                    data,
                    method: "POST",
                    withCredentials: true,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => response.data),
        
        sendMail: (data: IEmail, params: IHttpRequestServiceOptions = {}) =>
                httpRequestService
                    .request<IEmail>('/sendEmail', params, {
                        data,
                        method: "POST"
                    })
                    .then(response => response.data),

        getCurrentUser: (params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<IUser[]>('/users/current', params)
                .then(response => response.data),

        getUsersList: (params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<IUser[]>('/users', params)
                .then(response => response.data),

        createUser: (data: IRegister, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<number>('/register', params, {
                data,
                method: 'POST',
                })
                .then(response => response.data),

        updateUser: (data: IUserWithPassword, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<string>(`/update_user`, params, {
                data,
                method: 'PUT',
                })
                .then(() => {}),

        removeUser: (id: string, params: IHttpRequestServiceOptions = {}) =>
            httpRequestService
                .request<void>(`/users/${id}`, params, {
                method: 'DELETE',
                })
                .then(() => {}),
    },
};

export default identityService;
